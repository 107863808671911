import React from "react";
import {Link} from "react-router-dom";
import { BsFire } from "react-icons/bs";

import Rating from "../../../shared-components/RatingStars/RatingStars";

import "./style.css";

const StorageUnitsList = ({ storageFacilties }) => {
  return (
    <div className="flex flex-col">
        {storageFacilties?.results?.map((storage) => {
          return (
            <div
              className="order-cards card-body p-4 mb-3 position-relative "
              key={storage._id}
            >
              {storage.has_promotion ? (
                <span className="position-absolute top-10 end-0 py-1 px-2 pe-4 promotion-banner">
                  <BsFire size={20} className="mb-1 me-1" />

                  <span className="text-danger fw-semibold">
                    Promotions Available
                  </span>
                </span>
              ) : (
                ""
              )}
              <div className="d-flex flex-column flex-md-row gap-5">
                  <div>
                      {storage.facility_images[0]?.image ?
                        (<img src={storage.facility_images[0]?.image}  alt="Storage Facility Image" 
                          style={{width:'250px' ,height:'250px',
                            objectFit: 'cover',
                            borderRadius:'8px',
                          }} />)
                        :
                        (
                          <div className="d-flex align-items-center justify-content-center"
                          style={{width:'250px' ,height:'250px',
                            objectFit: 'cover',
                            borderRadius:'8px',
                            backgroundColor:'#D8D8D8',
                          }}
                        >
                          <p className="n-font" style={{
                            fontSize:'50px',
                            fontWeight:800,
                            color:'#FFCC00',
                            marginBottom:'0px'
                          }}
                          >{storage.name?.slice(0, 2).toUpperCase()}</p>
                        </div>
                        )
                        }
                  </div>
                      <div className="d-flex flex-column justify-content-between">
                           <div className="d-flex flex-column gap-2">
                           <p className="order-cards-head a-font mb-0">{storage.name}</p>
                            <Rating
                              value={storage.average_rating ? storage.average_rating : 0}
                              text={`${
                                storage.total_persons_rating
                                  ? storage.total_persons_rating
                                  : 0
                              } reviews `}
                              color="#8704f5"
                              fontSize={"15px"}
                            />
                           </div>
                          <div className="d-flex flex-column gap-3">
                           
                            <p className="order-cards-p mb-0 a-font">{storage.location.address}</p>

                            <div>
                              <span className="order-cards-p a-font fw-semibold">Office Hours: </span>
                              <span className="order-cards-p a-font">{storage.working_hours}</span>
                            </div>

                            <div>
                              <span className="order-cards-p a-font fw-semibold">Access Hours: </span>
                              <span className="order-cards-p a-font">{storage.access_hours}</span>
                            </div>

                       </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <div className="">
                      <span className="a-font order-cards-p">Starting From</span>
                      <div>
                      <span className="a-font" style={{fontSize:'38px', fontWeight:800, color:'#202224'}}>
                        ${storage.minimum_price}
                      </span>
                      <span style={{fontSize:'14px', fontWeight:500, color:'#202224'}} >/month</span>
                      </div>
                     
                    </div>
                    <Link
                      to={`/storages/results/${storage.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                        <button className="px-3 p-1 a-font" style={{backgroundColor:'#ffcc00' ,borderRadius:'5px' , 
                          border:'none',fontSize:'16px',fontWeight:600,color:'#202224' }}>
                            View Storage Units
                        </button>
                    </Link>
                  </div>
              </div>
              
            </div>
          );
        })}
    </div>
  );
};
export default StorageUnitsList;
