import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage ,useFormikContext} from "formik";
import * as Yup from "yup";
import {
  MdLocationCity,
  MdDriveFileRenameOutline,
  MdOutlineDescription,
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IoIosAddCircle } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import useImageUploader from "../../../../hooks/useImageUpload";
import AddUnitLocation from "./AddUnitLocation";
import { createStorageUnit } from "../../../../actions/storageUnitActions";
import AddUnitMap from "./AddUnitMap";
import { IoMdCloudUpload } from 'react-icons/io';
import { TbFileUpload } from "react-icons/tb";
import { IoCheckmark } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { FaMinus } from 'react-icons/fa';
import { FiMinus, FiPlus } from "react-icons/fi";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiClock } from "react-icons/hi2";
import { GoChevronDown } from "react-icons/go";
import { SlTrash } from "react-icons/sl";
const AddUnitForm = ({ onLocationChange }) => {
  // const { setFieldValue } = useFormikContext();
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const cloudName = "dxqfv1xa2";
  const { handleImageUpload, uploading } = useImageUploader(cloudName);
  const dispatch = useDispatch();

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const urls = await handleImageUpload(files);

    // Convert urls to the desired format
    const formattedImages = urls.map((url) => ({
      image: url,
    }));

    // Update the uploadedImages state with the formatted array
    setUploadedImages((prevImages) => [...prevImages, ...formattedImages]);
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) => {
      // Create a copy of the previous state array
      const updatedImages = [...prevImages];
      // Remove the image at the specified index
      updatedImages.splice(index, 1);
      // Return the updated array
      return updatedImages;
    });
  };

  // Define the getLocationData function
  const getLocationData = (data, id, setFieldValue) => {
    if (!data) {
      // If data is not available, reset the form field value to null
      setFieldValue(id, null);
      return;
    }

    // If data is available, update the form field value accordingly
    onLocationChange(data);
    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };
    setFieldValue(id, locationData);
    // console.log("fieldid is " ,setFieldValue );
    
  };

  const daysOfWeek = [
    { id: "SUN", value: "Sunday" },
    { id: "MON", value: "Monday" },
    { id: "TUE", value: "Tuesday" },
    { id: "WED", value: "Wednesday" },
    { id: "THU", value: "Thursday" },
    { id: "FRI", value: "Friday" },
    { id: "SAT", value: "Saturday" },
  ];
  const [mapData, setMapData] = useState({
    properties: {
      lat: 40.7127281,
      lon: -74.0060152,
      formatted: "New York, NY, United States of America",
    },
  });
  // Function to convert 24-hour format to 12-hour format
  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(":");
    const hour12 = hour % 12 || 12;
    const period = hour < 12 ? "AM" : "PM";
    return `${hour12}:${minute} ${period}`;
  };

  const initialValues = {
    name: "",
    location: null,
    address: "",
    starting_work_time: "",
    ending_work_time: "",
    starting_work_day: "",
    ending_work_day: "",
    starting_access_time: "",
    ending_access_time: "",
    starting_access_day: "",
    ending_access_day: "",
    description: "",
    facility_images: [],
    features: [{ features_text: "" }],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    location: Yup.object().required("Location is required"),
    address: Yup.string().required("Address is required"),
    starting_work_time: Yup.string().required("Starting Work Time is required"),
    ending_work_time: Yup.string().required("Ending Work Time is required"),
    starting_work_day: Yup.string().required("Starting Work Day is required"),
    ending_work_day: Yup.string().required("Ending Work Day is required"),
    starting_access_time: Yup.string().required(
      "Starting Access Time is required",
    ),
    ending_access_time: Yup.string().required("Ending Access Time is required"),
    starting_access_day: Yup.string().required(
      "Starting Access Day is required",
    ),
    ending_access_day: Yup.string().required("Ending Access Day is required"),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
      // console.log("submitting is " , values);
    values.storage_provider_ids = serviceProvider.id;
    values.facility_images = uploadedImages;
    values.working_hours = `${values.starting_work_day} - ${
      values.ending_work_day
    }  ${convertTo12HourFormat(values.starting_work_time)} - ${convertTo12HourFormat(
      values.ending_work_time,
    )}`;
    values.access_hours = `${values.starting_access_day} - ${
      values.ending_access_day
    }  ${convertTo12HourFormat(values.starting_access_time)} - ${convertTo12HourFormat(
      values.ending_access_time,
    )}`;
    try {
      await validationSchema.validate(values, { abortEarly: false });
      dispatch(createStorageUnit(values));
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      // toast.error("Failed to create storage unit");
    }
  };

  ////////          WORKING HOURS SELECTION - By A
  const [showModal, setShowModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([0, 1, 2, 3, 4]); // Default selected days: Monday to Friday
  const allDays = ['M', 'T', 'W', 'T', 'F','S','S'];

  const handleOpenModal=()=>setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [showModal2, setShowModal2] = useState(false);
  const handleOpenModal2=()=>setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);

  const [workingHours, setWorkingHours] = useState({
    start: 9, // Default start time
    end: 17,  // Default end time
  });
  const toggleDaySelection = (index, idc, setFieldValue) => {
    const newSelectedDays = [...selectedDays];
    const isSelected = newSelectedDays.includes(index);
  
    if (isSelected) {
      // Unselect the day and all subsequent days
      const removeIndex = newSelectedDays.indexOf(index);
      newSelectedDays.splice(removeIndex); // Removes the selected day and all subsequent days
    } else {
      // Select the day and any days in between
      newSelectedDays.push(index);
      newSelectedDays.sort((a, b) => a - b); // Ensure the days are sorted
  
      // Select all days between the first and last selected days
      const first = Math.min(...newSelectedDays);
      const last = Math.max(...newSelectedDays);
      for (let i = first; i <= last; i++) {
        if (!newSelectedDays.includes(i)) {
          newSelectedDays.push(i);
        }
      }
      newSelectedDays.sort((a, b) => a - b); // Re-sort the selected days
    }
  
    setSelectedDays(newSelectedDays);

    if (newSelectedDays.length > 0) {
      const startingDay = newSelectedDays[0]; // Get the first selected day
      const endingDay = newSelectedDays[newSelectedDays.length - 1]; // Get the last selected day
  
      // Map index to day abbreviation (Mon, Tue, etc.)
      const daysMap = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
      setFieldValue("starting_work_day", daysMap[startingDay]);
      setFieldValue("ending_work_day", daysMap[endingDay]);
      
    }
  };
  

  const handleTimeChange = (type, value) => {
    const newValue = parseInt(value, 10);
    setWorkingHours((prev) => {
      const updatedHours = { ...prev, [type]: newValue };
      return updatedHours;
    });
  };

  const toggleaccessDaySelection = (index, idc, setFieldValue) => {
    const newSelectedDays = [...selectedDays];
    const isSelected = newSelectedDays.includes(index);
  
    if (isSelected) {
      // Unselect the day and all subsequent days
      const removeIndex = newSelectedDays.indexOf(index);
      newSelectedDays.splice(removeIndex); // Removes the selected day and all subsequent days
    } else {
      // Select the day and any days in between
      newSelectedDays.push(index);
      newSelectedDays.sort((a, b) => a - b); // Ensure the days are sorted
  
      // Select all days between the first and last selected days
      const first = Math.min(...newSelectedDays);
      const last = Math.max(...newSelectedDays);
      for (let i = first; i <= last; i++) {
        if (!newSelectedDays.includes(i)) {
          newSelectedDays.push(i);
        }
      }
      newSelectedDays.sort((a, b) => a - b); // Re-sort the selected days
    }
  
    setSelectedDays(newSelectedDays);

    if (newSelectedDays.length > 0) {
      const startingDay = newSelectedDays[0]; // Get the first selected day
      const endingDay = newSelectedDays[newSelectedDays.length - 1]; // Get the last selected day
  
      // Map index to day abbreviation (Mon, Tue, etc.)
      const daysMap = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
      setFieldValue("starting_access_day", daysMap[startingDay]);
      setFieldValue("ending_access_day", daysMap[endingDay]);
      
    }
  };

  const handleSave = (values) => {
    // console.log('Form Submitted:', values);
    // You can make an API call or update the state here
    handleCloseModal(); // Close the modal after saving
  };
  
  return (
    <div className="">
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values ,resetForm }) => (
          <Form className="">
            {/* Name */}
          <div className="d-flex col-md-12 gap-5">
              <div className="col">
              <div className="">
                <label className="form-label a-font mb-0" htmlFor="name">Name</label>
                <div className="d-flex form-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                    <MdDriveFileRenameOutline size={20} />
                  <Field
                    type="text"
                    name="name"
                    className="form-input custom-placeholder"
                    placeholder="storage facility name..."
                    style={{width:'100%',height:'90%',border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                  />
                </div>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Location */}
              <div className="mt-3">
                <label className="form-label a-font mb-0" htmlFor="location">Location</label>
                <div className="d-flex form-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                    <MdLocationCity size={20} />

                  <Field name="location" type="text" className=""
                  style={{width:'100%',height:'90%',border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}} 
                  >
                    {({ field, form }) => (
                      <div className="" style={{width:'100%'}}>
                        <AddUnitLocation
                          id="location"
                          getLocationData={(data) =>
                            getLocationData(data, field.name, form.setFieldValue)
                          }
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Address */}
              <div className="mt-3">
                <label className="form-label a-font mb-0" htmlFor="address">Address</label>
                <div className="d-flex form-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                    <MdDriveFileRenameOutline size={20} />
                  <Field
                    type="text"
                    name="address"
                    className="form-input custom-placeholder"
                    placeholder="storage facility address ..."
                    style={{width:'100%',height:'90%',border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}} 

                  />
                </div>
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-danger"
                />
              </div>


              <div className="d-flex gap-3 mt-4">
              <div className="col">
      {/* Trigger for Modal */}
      <div
        className="d-flex px-3 align-items-center justify-content-between"
        style={{
          backgroundColor: '#FAFBFD',
          border: '1px solid #FFCC00',
          cursor: 'pointer',
          maxWidth: '239px',
          borderRadius: '8px',
          height: '46px',
        }}
        onClick={handleOpenModal}
      >
        <HiClock />
        <span className="n-font" style={{ fontSize: '14px', fontWeight: 500, color: '#323232' }}>
          Select Work Hours
        </span>
        <GoChevronDown />
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <p
            className="mb-1 mt-2 a-font"
            style={{ fontSize: '30px', fontWeight: 700, color: '#202224' }}
          >
            Select Working Hours
          </p>
        </Modal.Header>
        <Modal.Body>
            <>
            <div className="d-flex align-items-center justify-content-between mb-4">
                  <p
                    className="a-font mb-0"
                    style={{ fontSize: '16px', fontWeight: 600, color: '#202224' }}
                  >
                    Days
                  </p>
                  <div className="d-flex align-items-center">
                    {allDays.map((day, index) => (
                      <Field name="starting_work_day">
                        {({ field, form }) => (
                      <div
                        key={index}
                        onClick={() => toggleDaySelection(index, field.name, form.setFieldValue)}
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: '38px',
                          height: '38px',
                          borderRadius: '50%',
                          backgroundColor: selectedDays.includes(index) ? '#FFD700' : '#D3D3D3',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          margin: '0 5px',
                          color: '#000',
                        }}
                      >
                        {day[0]}
                      </div>
                      )}
                      </Field>
                    ))}
                  </div>
                </div>

                {/* <form> */}
                  <div>
                    {selectedDays.map((dayIndex) => {
                      const isFirstSelected = selectedDays.indexOf(dayIndex) === 0;
                      const isLastSelected = selectedDays.indexOf(dayIndex) === selectedDays.length - 1;

                      return (
                        <div
                          key={dayIndex}
                          className="d-flex align-items-center justify-content-between"
                          style={{
                            border: '1px solid #ddd',
                            padding: '10px',
                            borderRadius: isFirstSelected
                              ? '10px 10px 0 0'
                              : isLastSelected
                              ? '0 0 10px 10px'
                              : '0',
                          }}
                        >
                          <p className="mb-0" style={{ fontWeight: '600', width: '120px' }}>
                            {allDays[dayIndex]}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            {/* Start Time */}
                            <Field
                              type="time"
                              name={'starting_work_time'}
                              className="form-time-selection a-font px-3 p-1"
                              style={{ borderRadius: '8px' }}
                            />

                            <span>to</span>
                            {/* End Time */}
                            <Field
                              type="time"
                              name={'ending_work_time'}
                              className="form-time-selection a-font px-3 p-1"
                              style={{ borderRadius: '8px' }}
                            />
                         
                            <SlTrash
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleDaySelection(dayIndex, null)}
                            />
                          </div>
                          <FiPlus
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (!selectedDays.includes(dayIndex + 1)) {
                                toggleDaySelection(dayIndex + 1,null);
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <p
                    className="a-font mb-0 mt-3"
                    style={{ fontSize: '14px', fontWeight: 600, color: '#202224' }}
                  >
                    Note: Days will be selected as a range, and the same time will be applicable for
                    all days.
                    <ErrorMessage
                      name={'starting_work_time'}
                      component="div"
                      className="text-danger"
                    />
                    <ErrorMessage
                      name={'ending_work_time'}
                      component="div"
                      className="text-danger"
                    />
                  </p>

                  {/* Footer Buttons */}
                  <Modal.Footer>
                    <button
                      type="button"
                      className="p-1 px-3 d-flex gap-2 align-items-center"
                      style={{
                        backgroundColor: '#F1F4F9',
                        borderRadius: '6px',
                        border: 'none',
                        width: '96px',
                      }}
                      onClick={handleCloseModal}
                    >
                      <RxCross1 color="black" size={12} />
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="p-1 px-3 d-flex gap-2 align-items-center"
                      style={{
                        backgroundColor: '#FFCC00',
                        borderRadius: '6px',
                        border: 'none',
                        width: '96px',
                      }}
                      onClick={handleCloseModal}
                    >
                      <IoCheckmark size={12} /> Save
                    </button>
                  </Modal.Footer>
            </>
        </Modal.Body>
      </Modal>
    </div>
                
    <div className="col">
      {/* Trigger for Modal */}
      <div
        className="d-flex px-3 align-items-center justify-content-between"
        style={{
          backgroundColor: '#FAFBFD',
          border: '1px solid #FFCC00',
          cursor: 'pointer',
          maxWidth: '239px',
          borderRadius: '8px',
          height: '46px',
        }}
        onClick={handleOpenModal2}
      >
        <HiClock />
        <span className="n-font" style={{ fontSize: '14px', fontWeight: 500, color: '#323232' }}>
          Select Acccess Hours
        </span>
        <GoChevronDown />
      </div>

      {/* Modal */}
      <Modal show={showModal2} onHide={handleCloseModal2} centered dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <p
            className="mb-1 mt-2 a-font"
            style={{ fontSize: '30px', fontWeight: 700, color: '#202224' }}
          >
            Select Access Hours
          </p>
        </Modal.Header>
        <Modal.Body>
            <>
            <div className="d-flex align-items-center justify-content-between mb-4">
                  <p
                    className="a-font mb-0"
                    style={{ fontSize: '16px', fontWeight: 600, color: '#202224' }}
                  >
                    Days
                  </p>
                  <div className="d-flex align-items-center">
                    {allDays.map((day, index) => (
                      <Field name="starting_access_day">
                        {({ field, form }) => (
                      <div
                        key={index}
                        onClick={() => toggleaccessDaySelection(index, field.name, form.setFieldValue)}
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          width: '38px',
                          height: '38px',
                          borderRadius: '50%',
                          backgroundColor: selectedDays.includes(index) ? '#FFD700' : '#D3D3D3',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          margin: '0 5px',
                          color: '#000',
                        }}
                      >
                        {day[0]}
                      </div>
                      )}
                      </Field>
                    ))}
                  </div>
                </div>

                {/* <form> */}
                  <div>
                    {selectedDays.map((dayIndex) => {
                      const isFirstSelected = selectedDays.indexOf(dayIndex) === 0;
                      const isLastSelected = selectedDays.indexOf(dayIndex) === selectedDays.length - 1;

                      return (
                        <div
                          key={dayIndex}
                          className="d-flex align-items-center justify-content-between"
                          style={{
                            border: '1px solid #ddd',
                            padding: '10px',
                            borderRadius: isFirstSelected
                              ? '10px 10px 0 0'
                              : isLastSelected
                              ? '0 0 10px 10px'
                              : '0',
                          }}
                        >
                          <p className="mb-0" style={{ fontWeight: '600', width: '120px' }}>
                            {allDays[dayIndex]}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            {/* Start Time */}
                            <Field
                              type="time"
                              name={'starting_access_time'}
                              className="form-time-selection a-font px-3 p-1"
                              style={{ borderRadius: '8px' }}
                            />
                            
                            <span>to</span>
                            {/* End Time */}
                            <Field
                              type="time"
                              name={'ending_access_time'}
                              className="form-time-selection a-font px-3 p-1"
                              style={{ borderRadius: '8px' }}
                            />
                            <SlTrash
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleaccessDaySelection(dayIndex, null)}
                            />
                          </div>
                          <FiPlus
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (!selectedDays.includes(dayIndex + 1)) {
                                toggleaccessDaySelection(dayIndex + 1,null);
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <p
                    className="a-font mb-0 mt-3"
                    style={{ fontSize: '14px', fontWeight: 600, color: '#202224' }}
                  >
                    Note: Days will be selected as a range, and the same time will be applicable for
                    all days.
                    <ErrorMessage
                      name={'starting_access_time'}
                      component="div"
                      className="text-danger"
                    />
                    <ErrorMessage
                      name={'ending_access_time'}
                      component="div"
                      className="text-danger"
                    />
                  </p>

                  {/* Footer Buttons */}
                  <Modal.Footer>
                    <button
                      type="button"
                      className="p-1 px-3 d-flex gap-2 align-items-center"
                      style={{
                        backgroundColor: '#F1F4F9',
                        borderRadius: '6px',
                        border: 'none',
                        width: '96px',
                      }}
                      onClick={handleCloseModal2}
                    >
                      <RxCross1 color="black" size={12} />
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="p-1 px-3 d-flex gap-2 align-items-center"
                      style={{
                        backgroundColor: '#FFCC00',
                        borderRadius: '6px',
                        border: 'none',
                        width: '96px',
                      }}
                      onClick={handleCloseModal2}
                    >
                      <IoCheckmark size={12} /> Save
                    </button>
                  </Modal.Footer>
            </>
        </Modal.Body>
      </Modal>
    </div>

    

              </div>
    
              {/* Description */}
              <div className="mt-3">
                <label className="a-font mb-0 form-label" htmlFor="description">Description (Optional)</label>

                <div className="">
                <Field
                    name="description"
                    as='textarea'
                    className=""
                    placeholder="Add a description..."
                    style={{
                      width: '100%',
                      height: '300px',
                      border: '1px solid #D8D8D8',
                      borderRadius: '8px',
                      backgroundColor: '#FAFCFF',
                      padding: '10px',
                      textAlign: 'left', 
                      resize: 'none',     
                      direction: 'ltr',   
                    }}
                  />
                  </div>

              </div>

              </div>
                  <div className="col">
                        <AddUnitMap mapData={mapData} />

            {/* Features */}
                  <FieldArray name="features">
                    {({ push, remove }) => (
                      <div className="mt-3">
                        <p className="a-font" style={{ fontSize: '16px', fontWeight: 500 }}>
                          Select Features
                        </p>
                        <div className="d-flex flex-column gap-3">
                          {['Climate Controlled', 'Vehicle Storage', 'Commercial Storage', 'Outdoor Storage'].map(
                            (featureText, index) => {
                              const isSelected = values.features.some(f => f.features_text === featureText);

                              return (
                                <span
                                  key={index}
                                  className={`d-flex p-1 ps-1 gap-2 n-font featurespan ${
                                    isSelected ? 'selected' : ''
                                  }`}
                                  onClick={() => {
                                    if (isSelected) {
                                      const featureIndex = values.features.findIndex(
                                        f => f.features_text === featureText
                                      );
                                      remove(featureIndex);
                                    } else {
                                      push({ features_text: featureText });
                                    }
                                  }}
                                  style={{ cursor: 'pointer', position: 'relative', transition: 'all 0.3s' }}
                                >

                                  <span>
                                    {isSelected ? <IoCheckmark/> : <FiPlus/>}
                                  </span>

                                  <span className="remove-icon" style={{ display: 'none' }}>
                                    <FiMinus />
                                  </span>

                                  {featureText}
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  </div>
            </div>

            <FieldArray name="facility_images">
            {({ push, remove }) => (
              <div className="mt-4">
                {/* Image Upload Input */}
                <div
                  style={{
                    border: "2px solid #FFDF61",
                    boxShadow:'0px 0px 0px 4px #FFDF6140',
                    borderRadius: "32px",
                    padding: "24px",
                    textAlign: "center",
                    maxHeight:'170px',
                  }}
                >
                 <div>
                  {!uploadedImages || uploadedImages.length === 0 ? (
                    <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
                      <div
                        className="d-flex justify-content-center align-items-center mx-auto mb-3"
                        style={{
                          backgroundColor: "#F1F4F9",
                          width: "48px",
                          height: "48px",
                          borderRadius: "123px",
                        }}
                      >
                        <TbFileUpload size={25} />
                      </div>
                      <span
                        style={{
                          color: "#FFCC00", cursor: "pointer", fontSize: "16px", fontWeight: 600,
                        }}
                      >
                        Click here{" "}
                        <span style={{ color: "#475569", cursor: "pointer" }}>
                          to upload your file or drag.
                        </span>
                      </span>
                      <p
                        className="mt-2 mb-0"
                        style={{ fontSize: "14px", color: "#94A3B8" }}
                      >
                        Supported Format: SVG, JPG, PNG (10mb each)
                      </p>
                    </label>
                  ) : null}
                </div>

                <div className="d-flex align-items-center gap-3">
                {uploadedImages.map((image, index) => (
                  <div
                    key={index}
                    className="d-flex imagehandling"
                  >
                      <div className="onhovereffect">
                          <img
                            src={image.image}
                            className="img-fluid rounded"
                            alt="storagePic"
                            style={{
                              width: 120,
                              height: 120,
                              objectFit: "cover",
                            }}
                          />
                      </div>
                      <div className="hide">
                      <span 
                      className="px-3 p-1"
                      style={{backgroundColor:'#EF3826',fontSize:'9px', fontWeight:600,border:'1px solid #979797',borderRadius:'6px',color:'white'}}
                       type="button" onClick={() => removeImage(index)}>
                        - Remove
                      </span>
                      </div>
                  </div>
                ))}
                {uploadedImages != 0 ? (
                  <div className="flex-end">
                    <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
                    <span
                    className="px-3 p-1 text-end"
                    style={{backgroundColor:'#EF3826',fontSize:'9px', fontWeight:600,border:'1px solid #979797',borderRadius:'6px',color:'white'}}
                    type="button">
                      + Add More
                    </span>
                    </label>
                  </div>
                ) : null }
              </div>

                </div>
                <input
                    id="upload-input"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      handleFileChange(event).then(() => {
                        // Formik will automatically update the form field
                      });
                    }}
                    multiple
                    style={{
                      display: "none",
                    }}
                  />
                <ErrorMessage
                  name="facility_images"
                  component="div"
                  className="text-danger"
                />
              {/* Display Uploaded Images */}
             
            </div>
          )}
        </FieldArray>

            <div className="d-flex flex-row-reverse gap-3 mt-5 mx-3">
            <button 
            className="p-1 px-3 d-flex gap-2 align-items-center"
            style={{backgroundColor:'#F1F4F9' , borderRadius:'6px', border:'none',
               width:'96px',
            }}
              type="button"
              onClick={() => resetForm()}
            >
              <RxCross1 color="black" size={12}/>  
              Cancel
            </button>

            <button 
            className="p-1 px-3 d-flex gap-2 align-items-center"
            style={{backgroundColor:'#FFCC00' , borderRadius:'6px', border:'none', width:'96px',
              // boxShadow:'2px 2px 2px 2px #FACC001C'
            }}
              type="submit"
              disabled={isSubmitting}
            >
              <IoCheckmark size={12}/> {isSubmitting ? "Submitting..." : " Save"}
            </button>

            {/* <button
              type="submit"
              className="btn btn-dark text-dark my-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "CREATE NEW FACILITY"}
            </button> */}
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );
};

export default AddUnitForm;
