import React, { useState } from "react";
import { Link, useNavigate ,createSearchParams} from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useSelector } from "react-redux";
import { BsCartCheck } from "react-icons/bs";
import Notification from "../Notifications/Notifications";
import "./navbar.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoWarning } from "react-icons/io5";

import { logoutUser } from "../../actions/userActions";
import { USER_INFO_CLEAR } from "../../constants/userConstants";
import { Spinner } from "react-bootstrap";
import activateStripe from "../../hooks/activateStripe";
import SizeGuideDropdownContent from "./SizeGuideDropdownContent";
import { FaUser } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";

const ProviderNavbar = () => {
  const { user } = useSelector((state) => state.userLogin);
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const { message, error } = useSelector((state) => state.userLogout);
  const { info } = useSelector((state) => state.userInfo);

  const { cartItems, loading } = useSelector((state) => state.cartGetItems);
  const navigate = useNavigate();
  const [stripeLoading, setStripeLoading] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const dispatch = useDispatch();
  let storageUnits;
  if (!loading && cartItems) {
    storageUnits = cartItems[0].storage_unit;
  }

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
  }, [message, error]);

  async function handleActiveStripe() {
    setStripeLoading(true);
    const status = await activateStripe(user);
    setStripeLoading(status);
  }

  // Handlers
  const logoutHandler = () => {
    dispatch(logoutUser());
    dispatch({ type: USER_INFO_CLEAR });
    navigate("/");
  };
  const homeLink =
    info && info.is_service_provider
      ? "/service-provider-dashboard/analytics"
      : "/";

  /////////To find storage ; BY deafult in button prop location is new york
  const [formDatabutton, setFormDatabutton] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const ButtonState = (location) => {
    setFormDatabutton((prev) => {
      const updatedData = { ...prev, location };
  
      // console.log("Updated form data:", updatedData);
      localStorage.setItem("us_Storage_facility", JSON.stringify(updatedData));
  
      navigate({
        pathname: "/storages/results",
        search: `?${createSearchParams(updatedData)}`,
      });
  
      return updatedData;
    });
  };
  // console.log("~~~~~~~~  provider into " , serviceProvider);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light nav"
      style={{backgroundColor:'#F5F6FA'}}
    >
      <div
        className="container-fluid p-3"
        style={{ width: "94%" , backgroundColor:'#000034' , height:'80px', marginTop: "20px",  
          borderRadius: "25px"
          }}
      >
        <Link to={homeLink} className="navbar-brand">
        <span
            className="mineTextOrange ms-3"
            style={{ marginRight: "10px", position: "relative", display: "inline-block" }}
          >
            <img src="/images/FindxStorage3.png" height={35} alt="Logo" />
          </span>

        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsNavOpen(!isNavOpen)}
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
          style={{ color: "white" }}
        >
          <i className="fas fa-bars"></i>
        </button>
       

        {/* <div className="d-flex justify-content-center align-items-center w-full">
                <p className="a-font mb-0" style={{fontSize:'30px',fontWeight:600 , color:'white'}}>Service Providers Dashboard</p> 
            </div> */}
        <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
            style={{
              backgroundColor: isNavOpen ? "#000034" : "transparent", // Change color if nav is open
            }}
          >

          <div
                className="justify-content-center align-items-center text-center mx-auto"
              >
                <p
                  className="a-font mb-0"
                  style={{
                    fontSize: "30px",
                    fontWeight: 600,
                    color: "white",
                  }}
                >
                  Service Provider Dashboard
                </p>
              </div>
            {/* <div className="d-flex justify-content-center align-items-center w-full">
                <p className="a-font mb-0" style={{fontSize:'30px',fontWeight:600 , color:'white'}}>Service Providers Dashboard</p> 
            </div> */}
          <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-items-center">
            <li className="nav-item m-0 m-lg-1 m-xl-2 my-3">
                  <Notification />
            </li>
            <li className="nav-item dropdown">
            <div className="d-flex align-items-center">
                  <img
                    src="/images/usericondummy.png"
                    alt="user review"
                    className="rounded-circle me-3"
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  />
                  <div className="d-flex flex-column justify-content-center align-items-start">
                  <p className="n-font mb-0" style={{fontSize:'14px', color:'white' ,fontWeight:'700px',}}>{serviceProvider ? serviceProvider.name : info.email}</p>
                  <p  className="n-font mb-0" style={{fontSize:'12px', color:'white' ,fontWeight:'600px',}} >Admin</p>
                  </div>
                  </div>
                 
              </li>
              <li className="nav-item dropdown">
                  <span
                    className="nav-link"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                     <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 19.1C15.0258 19.1 19.1 15.0258 19.1 10C19.1 4.97421 15.0258 0.9 10 0.9C4.97421 0.9 0.9 4.97421 0.9 10C0.9 15.0258 4.97421 19.1 10 19.1Z"
                          stroke="white"
                          strokeWidth="0.2"
                        />
                        <path
                          d="M10 10.7929L7.73162 8.14645C7.56425 7.95118 7.29289 7.95118 7.12553 8.14645C6.95816 8.34171 6.95816 8.65829 7.12553 8.85355L9.69695 11.8536C9.86432 12.0488 10.1357 12.0488 10.303 11.8536L12.8745 8.85355C13.0418 8.65829 13.0418 8.34171 12.8745 8.14645C12.7071 7.95118 12.4358 7.95118 12.2684 8.14645L10 10.7929Z"
                          fill="white"
                        />
                    </svg>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {user &&
                      user.service_provider &&
                      !user.is_stripe_account_active && (
                        <li>
                          <button
                            className="dropdown-item alert alert-warning"
                            onClick={handleActiveStripe}
                          >
                            <IoWarning size={25} color="orange" /> Please
                            activate your Stripe account{" "}
                            {stripeLoading && (
                              <Spinner
                                animation="border"
                                variant="warning"
                                size="sm"
                              />
                            )}
                          </button>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/myprofile"
                          >
                            Profile
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/myprofile">
                          Profile
                        </Link>
                      </li>
                    )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/analytics"
                          >
                            Dashboard
                          </Link>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/orders"
                          >
                            Orders
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/orders">
                          Orders
                        </Link>
                      </li>
                    )}
                    <li className="m-2">
                      <button
                        className="btn text-dark "
                        style={{ width: "100%" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default ProviderNavbar;
