import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";

const StorageUnitsFilter = ({
  onUpdateFilters,
  distinctiveStorageUnits,
  storageFacility,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    search: "",
    name: "",
    storage_facility__name: "",
  });
  const filterOptions = [{ value: "is_available", label: "Is Available" }];

  const handleChange = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      [e.target.id]: e.target.value,
    });
    // console.log("~~~units filters " , selectedFilters);
  };

  const handleFilterChange = (selectedOptions) => {
    const updatedFilters = { ...selectedFilters };

    selectedOptions?.forEach((option) => {
      switch (option.value) {
        case "is_available":
          updatedFilters.is_available = true;
          break;
        default:
          break;
      }
    });

    filterOptions?.forEach((option) => {
      if (
        !selectedOptions.some(
          (selectedOption) => selectedOption.value === option.value,
        )
      ) {
        switch (option.value) {
          case "is_available":
            updatedFilters.is_available = false;
            break;
          default:
            break;
        }
      }
    });

    setSelectedFilters(updatedFilters);
  };

  const selectedOptions = filterOptions.filter((option) => {
    switch (option.value) {
      case "is_available":
        return selectedFilters.is_available;
      default:
        return false;
    }
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '0.6px solid #D5D5D5',
      borderRadius: '4px',
      height: '28px', 
      minHeight: '28px', 
      '&:hover': {
        borderColor: '#D5D5D5',
      },
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: '14px', 
      display: 'flex',
      alignItems: 'center', 
      marginBottom: 'auto', 
    }),
    multiValue: (base) => ({
      ...base,
      // backgroundColor: '#e6f7ff', // Light blue background
      // borderRadius: '4px',
      // padding: '2px 4px',
      fontSize: '10px',
      marginBottom:'auto',
      // color: '#333',
    }),
    ValueContainer: (base)=>({
      ...base,
      padding:'0px',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: '0',
    }),
   
  }

  // console.log("~~~ unit filters and data " , distinctiveStorageUnits )

  return (
    <>
       <div className="d-flex align-items-center justify-content-between"
                >
                 <div><p className="service-card-heads a-font mb-0">Storage Unit Details</p></div>
                 <div className="d-flex align-items-center gap-3">      
                 <div className="d-flex align-items-center">
                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                       <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                       </svg>
                         <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                 </div>
                     <div 
                       style={{
                         width: '0.5px',         
                         backgroundColor: '#000000', 
                         height: '42px',     
                         margin: '0 10px'    
                       }}
                     ></div>      
                 <div className="">
                   <Select
                     options={filterOptions}
                     isMulti
                     value={selectedOptions}
                     onChange={handleFilterChange}
                     placeholder="Select filters..."
                     components={{
                       IndicatorSeparator: () => null,
                     }}
                     styles={customStyles}
                   />
                 </div>    

                 {distinctiveStorageUnits && Array.isArray(distinctiveStorageUnits) && (
                  <div className="input-order">
                    <select
                      id="name"
                      className="custom-input"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option selected value="">
                        Unit
                      </option>
                      {distinctiveStorageUnits.map((item, index) => (
                        <option key={index} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {storageFacility && Array.isArray(storageFacility) && (
                  <div className="input-order">
                    {/* <label htmlFor="business_type">Storage Facility</label> */}
                    <select
                      id="storage_facility__name"
                      className="custom-input"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option selected value="">
                        Facility
                      </option>
                      {storageFacility.map((item, index) => (
                        <option key={index} id={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )} 
                     
                 <div className="d-flex align-items-center gap-2">
                     <div className="input-order">
                         <input
                            type="text"
                            value={selectedFilters.search || ""}
                            placeholder="Search..."
                            className="custom-input"
                            id="search"
                            onChange={handleChange}
                         />

                     </div>
                     <button
                         type="button"
                         onClick={() => onUpdateFilters(selectedFilters)}
                         className=""
                         style={{
                           backgroundColor: '#ffcc00',
                           border: 'none',
                           borderRadius: '4.5px',
                           width:'30px',
                           height:'28px',
                           color: '#000034',
                         }}
                       >
                         <FaSearch size={14} />
                       </button>
                   </div>     
                 </div>
                </div>
    </>
  );
};

export default StorageUnitsFilter;
