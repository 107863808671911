import React, { useState, useEffect } from "react";

import DoughnutChart from "./DoughnutChart";
import UnitsMap from "./UnitsMap";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../actions/userActions";
import { USER_INFO_CLEAR } from "../../../constants/userConstants";
import '../style.css'
import { FaCircle, FaWeight } from "react-icons/fa";
import { Doughnut } from "react-chartjs-2";

const AnalyticsMapAndChart = ({ StorageUnitData, loading }) => {
  let availableSpace = StorageUnitData?.reduce(
    (acc, curr) => acc + curr.free_space,
    0,
  );
  let occupiedSpace = StorageUnitData?.reduce(
    (acc, curr) => acc + curr.occupied_space,
    0,
  );

  const [chartData, setChartData] = useState({
    labels: ["Available Space", "Occupied Space"],
    datasets: [
      {
        label: "Storage Unit Space",
        data: [availableSpace, occupiedSpace],
        backgroundColor: ["#FFCC00", "#FFFBEA"], // Adjust colors to match the image
        hoverBackgroundColor: ["#FFCC00", "#FFFBEA"],
        borderWidth: 0, // No border
      },
    ],
  });

  // Update chartData whenever availableSpace or occupiedSpace changes
  useEffect(() => {
    setChartData({
      labels: ["Available Space", "Occupied Space"],
      datasets: [
        {
          label: "Storage Unit Space",
          data: [availableSpace, occupiedSpace],
          backgroundColor: ["#FFCC00", "#FFFBEA"],
          hoverBackgroundColor: ["#FFCC00", "#FFFBEA"],
          borderWidth: 0,
        },
      ],
    });
  }, [availableSpace, occupiedSpace]);

  // Chart options for styling
  const chartOptions = {
    cutout: "87%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          color: "#333", 
          weight:600,
          font: {
            size: 16,
          },
          padding: 50, // Add padding between the legend and the main circle
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `${value}%`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    // layout: {
    //   padding: {
    //     top: 10, // Adjust padding around the top
    //     bottom: 30, // Add space between the chart and the legend
    //     left: 10,
    //     right: 10,
    //   },
    // },
  };

  // console.log("~~~~check level 2", StorageUnitData);
  // console.log("~~~~~~chart dtaa is " , chartData);

  return (
    <>
      <div className="col mb-2" style={{width:'584px'}}>
        <div className="p-4 h-100 service-card">
          <div className="card-body">
           <div className="d-flex gap-3 align-items-center">
                <p className="service-card-heads a-font">Facility Locations</p>
                <span 
                    style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        backgroundColor: '#ffcc00',
                        borderRadius: '118px',
                        color: '#FFCC00',
                        padding: '2px 10px',
                        display: 'inline-flex',         
                        alignItems: 'center',    
                        justifyContent: 'center', 
                        backgroundColor:"transparent",
                        border:'1px solid #FFCC00',
                        gap:'3px',
                      }}>
                        <FaCircle size={10}></FaCircle>
                    Live
                  </span>
           </div>
            {loading ? (
              <Spinner />
            ) : (
              <UnitsMap StorageUnitData={StorageUnitData} />
            )}
          </div>
        </div>
      </div>

      <div className="col mb-2" style={{width:'524px'}}>
        <div className="p-4 h-100 service-card">
          <div className="card-body d-flex flex-column" style={{ height: "100%" }}>
            <p className="service-card-heads a-font">Facility Space</p>
            {loading ? <Spinner /> : 
              <div className="d-flex justify-content-center align-items-center mt-1" style={{ width: "100%", height: "90%" }}>
                <div style={{ width: "100%", height: "95%" }}>
                  <Doughnut data={chartData} options={chartOptions} />
                </div>
              </div>
            }
          </div>
        </div>
      </div>


    </>
  );
};

export default AnalyticsMapAndChart;
