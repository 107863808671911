import React from "react";
import { BsSpeedometer } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";

const WelcomeOrange = () => {
  const navigate=useNavigate();
  return (
    <div className="container" style={{marginTop:'60px'}}>
      <div className="d-flex flex-column flex-lg-row justify-content-between w-100">
        <div className="col-12 col-lg-5">
          <p className="blueTextColr text-wrap sj-heading text-lg-start px-5"
            style={{
              color: '#000034',
            }}
          >
            Quality Ensured Storage Units.
          </p>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-end text-start">
          <p className="blueTextColr lh-sm text-wrap"
            style={{
              fontSize: '24px',
              fontWeight: 400,
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: '#000034',
              letterSpacing: '-1px',
            }}
          >
            All of the storage units that are listed on FindxStorage go through our quality assurance team.
          </p>

            <Link to={`http://blog.findxstorage.com/`}
            className="text-decoration-underline text-lg-start"
            style={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'left',
              color: '#ffcc00',
              textUnderlineOffset: '3px',
              cursor:'pointer',
            }}
              >
                Learn More
                <MdNavigateNext />
            </Link>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-around mt-4">
      <Link to={`/storage-type`}
        style={{
          textDecoration: 'none',
        }}
        >
              
        <div
          className="card m-2 storageunitcard"
          style={{
            border: 'none',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            width: '250px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <img src="/images/unit1.svg" alt="Climate Controlled" style={{ width: '70px', height: '70px' }} />
          </div>
          <p
            style={{
              color: '#000034',
              fontWeight: 500,
              fontSize: '24px',
              letterSpacing: '0px',
              lineHeight: '100%',
            }}
          >
            Climate Controlled Storage
          </p>
        </div>
        
      </Link>
      <Link to={`/storage-type`}
        style={{
          textDecoration: 'none',
        }}
        >
        <div
          className="card m-2 storageunitcard col-12 col-md-4 col-lg-2"
          style={{
            border: 'none',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            width: '250px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <img src="/images/unit2.svg" alt="Outdoor / Drive-Up Storage" style={{ width: '70px', height: '70px' }} />
          </div>
          <p
            style={{
              color: '#000034',
              fontWeight: 500,
              fontSize: '24px',
              letterSpacing: '0px',
              lineHeight: '100%',
            }}
          >
            Outdoor / Drive-Up Storage
          </p>
        </div>

        </Link>
      <Link to={`/storage-type`}
        style={{
          textDecoration: 'none',
        }}
        >
        <div
          className="card m-2 storageunitcard"
          style={{
            border: 'none',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            width: '250px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <img src="/images/unit3.svg" alt="Vehicle Storage" style={{ width: '70px', height: '70px' }} />
          </div>
          <p
            style={{
              color: '#000034',
              fontWeight: 500,
              fontSize: '24px',
              letterSpacing: '0px',
              lineHeight: '100%',
            }}
          >
            Vehicle Storage
          </p>
        </div>
        </Link>
      <Link to={`/storage-type`}
        style={{
          textDecoration: 'none',
        }}
        >
        <div
          className="card m-2 storageunitcard"
          style={{
            border: 'none',
            borderRadius: '25px',
            padding: '20px',
            textAlign: 'center',
            justifyContent: 'center',
            width: '250px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <img src="/images/unit4.svg" alt="Commercial & Business Storage" style={{ width: '70px', height: '70px' }} />
          </div>
          <p
            style={{
              color: '#000034',
              fontWeight: 500,
              fontSize: '24px',
              letterSpacing: '0px',
              lineHeight: '100%',
            }}
          >
            Commercial & Business Storage
          </p>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeOrange;
