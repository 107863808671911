import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

const UnitStorageReviewsTable = ({ data }) => {
  const [facilityRatings, setFacilityRatings] = useState(data?.facility_ratings || []);

  useEffect(() => {
    if (data?.facility_ratings) {
      setFacilityRatings(data.facility_ratings);
    }
  }, [data]);

  return (
    <Container className="d-flex flex-column gap-4 mt-5">
      {facilityRatings.length === 0 ? (
        <p className="text-center mt-5">This Facility currently has no reviews.</p>
      ) : (
        <Row>
          {facilityRatings.map((review, index) => (
            <Col key={review.id || index} className="mb-4 w-full">
              <Card className="p-3" style={{
                border: '1px solid #DFDFDF',borderRadius:'10px',height:'155px',
              }}>
                <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={review.avatar || "/images/usericondummy.png"}
                    alt={review.name}
                    className="rounded-circle me-3"
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  />
                  <h6 style={{fontSize:'12px', color:'#000034' ,fontWeight:'500px',}}>{review.user.email}</h6>
                  </div>
                    <div className="text-warning">
                      {"★".repeat(review.facility_ratings)}
                      {"☆".repeat(5 - review.facility_ratings)}
                    </div>
                </div>
                        <div style={{
                          border: "0.5px solid #DFDFDF",
                          margin: "20px 0",
                          width: "100%",   
                        }}></div>

                <p className="" style={{ fontSize: "12px", fontWeight:'400px', color: "#333333",textAlign: "justify" }}>
                  {review.comment ? `"${review.comment}"` : 'No comment Provided'}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default UnitStorageReviewsTable;
