import React, { useState, useEffect } from "react";
import ButtonGroupAndForm from "./ButtonGroupAndForm";
import FiltersAndStorages from "./FiltersAndStorages";
import ChooseOrangeDetails from "./ChooseOrangeDetails";
import Stepper from "../../../shared-components/Stepper/Stepper";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { FaBalanceScale } from "react-icons/fa";
import { MdOutlineCreditCardOff } from "react-icons/md";
import { TbCalendarCheck } from "react-icons/tb";
import { FaRegHandshake } from "react-icons/fa";

const Storages = () => {
  const [changeView, setChangeView] = useState({
    list: true,
    map: false,
  });

  const { loading, error, storageFacilties } = useSelector(
    (state) => state.usStorageFacilities,
  );

  

  // let paramsObj = {};
  // let storage = [];

  // const [searchParams] = useSearchParams();
  // for (const entry of searchParams.entries()) {
  //   const [param, value] = entry;
  //   if (param === "storage_type") {
  //     storage.push(value);
  //     paramsObj[param] = storage;
  //   } else {
  //     paramsObj[param] = value;
  //   }
  // }

  const dispatch = useDispatch();

  useEffect(() => {
    let paramsObj = JSON.parse(localStorage.getItem("us_Storage_facility"));

    dispatch(getAllUsStorageFacilities(paramsObj));
  }, []);

  return (
    <div className="w-full d-flex flex-column align-items-center" style={{backgroundColor:'#F5F6FA'}}>
      <div className="p-5" style={{maxWidth:'1420px'}}>
        <div className="d-flex col-12 flex-column flex-md-row" style={{backgroundColor:'#ffcc00', borderRadius:'30px' }}>

        <div className="d-flex flex-column col-12 col-md-6 gap-5" style={{paddingLeft:'80px' ,paddingBottom:'80px',paddingTop:'80px'}}>
              <p className="a-font mb-0" style={{fontSize:'54px', fontWeight:700 ,color:'#202224',lineHeight:'62px'}}>Search Thousands of Spaces Near You</p>
              <div className="d-flex col-10">
                
              <div className="d-flex flex-column col-6">
                  <div className="d-flex align-items-center gap-2">
                  <FaBalanceScale size={18}></FaBalanceScale>
                  <p className="a-font mb-0" style={{fontSize:'16px', fontWeight:600 ,color:'#202224'}}>Compare Storage Prices</p>
                  </div>

                  <div className="d-flex align-items-center gap-2">

                  <FaRegHandshake size={18} />
                  <p className="a-font mb-0" style={{fontSize:'16px', fontWeight:600 ,color:'#202224'}}>Find Exclusive Deals</p>
                  </div>

                </div>
                <div className="d-flex flex-column col-6">
                    <div className="d-flex align-items-center gap-2">
                    <TbCalendarCheck size={18}/>
                      <p className="a-font mb-0" style={{fontSize:'16px', fontWeight:600 ,color:'#202224'}}>Free Reservation</p>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                  <MdOutlineCreditCardOff size={18}/>
                  <p className="a-font mb-0" style={{fontSize:'16px', fontWeight:600 ,color:'#202224'}}>No Credit Card Required</p>
                  </div>
                </div>
              </div>
        </div>
        <div className="col-12 col-md-6" style={{ position: 'relative' }}>
                <div
                  className="heading-image-g"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderTopRightRadius: '30px',
                    borderBottomRightRadius: '30px',
                    zIndex: 1,
                  }}
                ></div>
                <img
                  src="/images/fstorage.jpg"
                  alt="Storage Illustration"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: '380px',
                    borderTopRightRadius: '30px',
                    borderBottomRightRadius: '30px',
                    position: 'relative',
                    zIndex: 0,
                  }}
                />
              </div>

        </div>
      </div>
      <div className="container py-2">
      {loading ? (
        <>
          <h3>Loading your query.</h3>
        </>
      ) : (
        <>
          {storageFacilties &&
            storageFacilties.results &&
            storageFacilties.results.length > 0 && (
              // <p className="a-font mb-0" style={{fontSize:'42px' ,fontWeight:700,color:'#202224',
              // }}>
              //   Find a Storage Unit Near You in{" "} {formatted}
              // </p>
              <p
              className="a-font mb-0 mb-0 mt-3"
              style={{
                fontSize: '42px',
                fontWeight: 700,
                color: '#202224',
              }}
            >
              {`Find a Storage Unit Near You in `}
              {storageFacilties?.results[0].location.address.split(',').map((part, index, arr) => (
              <React.Fragment key={index}>
                {part.trim()}
                {index < arr.length - 1 && ','}
                <br />
              </React.Fragment>
            ))}
            </p>
            
            )}
          {error && <h3>{error.msg}</h3>}
        </>
      )}
      <Stepper step1 step2 />
      <ButtonGroupAndForm
        changeView={changeView}
        onChangeView={setChangeView}
      />

      <FiltersAndStorages view={changeView} />

      <ChooseOrangeDetails />
    </div>
    </div>
  );
};

export default Storages;
