import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import UnitStorageReviewsTable from "./UnitStorageReviewsTable";

const AnalyticsRecentUnitsAndChart = ({ StorageUnitData }) => {
  const [selectedFacility, setSelectedFacility] = useState({ name: "" });
  const [facilities, setFacilities] = useState([]);


  const [selectedReviews, setSelectedReviews] = useState(null);
  const [itemWithRatings, setItemWithRatings] = useState(null);

  const [selectedFacility2, setSelectedFacility2] = useState({ name: "" });
  const [facilities2, setFacilities2] = useState([]);

  useEffect(() => {
    const facilityNames = StorageUnitData?.map((item) => item.name);
    setFacilities2(facilityNames);
  }, [StorageUnitData]);

  useEffect(() => {
    const itemWithRatings = StorageUnitData?.find(
      (item) => item.facility_ratings.length !== 0,
    );
    setItemWithRatings(itemWithRatings);
  }, [StorageUnitData]);


  useEffect(() => {
    if (selectedFacility2.name) {
      const filteredByName = StorageUnitData?.find(
        (item) => item.name === selectedFacility2.name,
      );
      setSelectedReviews(filteredByName || {});
    } else {
      setSelectedReviews(null);
    }
  }, [selectedFacility2, StorageUnitData]);

  const handleChange2 = (event) => {
    setSelectedFacility2({
      name: event.target.value,
    });
  };

  // console.log("~~~~~~~~~~ Reviews data " , selectedReviews)

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          display: false, 
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
        // categoryPercentage: 0.8, // Adjust category spacing
        // barPercentage: 0.5,      // Adjust bar width
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [8],
          color: '#ddd',
          drawBorder:false,
        },
        ticks: {
          stepSize: 1.0,
          callback: function (value) {
            return value.toFixed(1);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          color: "#333",
          font: {
            size: 16,
          },
          padding: 30,
        },
      },
    },
    // layout: {
    //   padding: {
    //     top: 20,
    //     bottom: 20,
    //     left: 30,
    //     right: 30,
    //   },
    // },
  };
  


  const [chartData, setChartData] = useState({
    labels: ["Total Space", "Occupied Space", "Available Space"],
    datasets: [
      {
        label: "Total Space",
        data: StorageUnitData.map((unit) => unit.total_storage_units),
        backgroundColor: "#FFCC00",
        borderRadius: {
          topLeft:15,
          topRight:15, 
        },
        maxBarThickness: 79,
      },
      {
        label: "Occupied Space",
        data: StorageUnitData.map((unit) => unit.occupied_storage_unit),
        backgroundColor: "#FF9871",
        borderRadius: {
          topLeft:15,
          topRight:15, 
        },
        maxBarThickness: 79,
      },
      {
        label: "Available Space",
        data: StorageUnitData.map((unit) => unit.available_storage_units),
        backgroundColor: "#4AD991",
        borderRadius: {
          topLeft:15,
          topRight:15, 
        },
        maxBarThickness: 79,
      },
    ],
  });

  const handleChange = (event) => {
    setSelectedFacility({
      name: event.target.value,
    });
  };

  useEffect(() => {
    const facilityNames = StorageUnitData?.map((item) => item.name);
    setFacilities(facilityNames);
  }, [StorageUnitData]);

  useEffect(() => {
    if (selectedFacility.name !== "") {
      const selectedFacilityData = StorageUnitData.find(
        (unit) => unit.name === selectedFacility.name,
      );

      if (selectedFacilityData) {
        setChartData({
          labels: [selectedFacilityData.name],
          datasets: [
            {
              label: "Total Space",
              data: [selectedFacilityData.total_storage_units],
              backgroundColor: "#FFCC00",
              borderRadius: {
                topLeft:15,
                topRight:15, 
              },
            },
            {
              label: "Occupied Space",
              data: [selectedFacilityData.occupied_storage_unit],
              backgroundColor: "#FF9871",
              borderRadius: {
                topLeft:15,
                topRight:15, 
              },
            },
            {
              label: "Available Space",
              data: [selectedFacilityData.available_storage_units],
              backgroundColor: "#4AD991",
              borderRadius: {
                topLeft:15,
                topRight:15, 
              },
            },
          ],
        });
      }
    } else {
      setChartData({
        labels: StorageUnitData.map((unit) => unit.name),
        datasets: [
          {
            label: "Total Space",
            data: StorageUnitData.map((unit) => unit.total_storage_units),
            backgroundColor: " #FFCC00",
            borderRadius: {
              topLeft:15,
              topRight:15, 
            },
          },
          {
            label: "Occupied Space",
            data: StorageUnitData.map((unit) => unit.occupied_storage_unit),
            backgroundColor: "#FF9871",
            borderRadius: {
              topLeft:15,
              topRight:15, 
            },
          },
          {
            label: "Available Space",
            data: StorageUnitData.map((unit) => unit.available_storage_units),
            backgroundColor: "#4AD991",
            borderRadius: {
              topLeft:15,
              topRight:15, 
            },
          },
        ],
      });
    }
  }, [selectedFacility.name, StorageUnitData]);


  
  return (
    <>
      <div className="col-md-7 mb-2">
        <div className="p-4 h-100 service-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <p className="service-card-heads a-font">Single Facility Analytics</p>
              <div>
                {/* <label htmlFor="duration">Storage Facility</label> */}
                <select
                  id="facilities"
                  value={selectedFacility.name}
                  onChange={handleChange}
                  className="form-select"
                  aria-label="Default select example"
                  style={{color:'#D5D5D5', border:'0.6px solid #D5D5D5'}}
                >
                  <option value="">Select Facility</option>
                  {facilities.map((item, index) => (
                    <option value={item} key={index} style={{color:'black'}}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center p-3" style={{ height: '500px', }}>
              <Bar data={chartData} options={options} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 mb-2">
        <div className="p-4 h-100 service-card">
        <div className="card-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <p className="service-card-heads a-font">User Reviews</p>
              <div>
                <select
                  id="facilities"
                  value={selectedFacility2.name}
                  onChange={handleChange2}
                  className="form-select"
                  aria-label="Default select example"
                  style={{color:'#D5D5D5', border:'0.6px solid #D5D5D5'}}
                >
                  <option value="">Select Facility</option>
                  {facilities2.map((item, index) => (
                    <option value={item} key={index} style={{color:'black'}}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <UnitStorageReviewsTable
              data={!selectedReviews ? itemWithRatings : selectedReviews}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsRecentUnitsAndChart;
