import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddUnitForm from "../ServiceProviderDashboardComponents/AddNewUnitPageComponents/AddUnitForm";
import AddUnitMap from "../ServiceProviderDashboardComponents/AddNewUnitPageComponents/AddUnitMap";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { CREATE_STORAGE_UNIT_CLEAR } from "../../../constants/storageUnitConstants";
import { MdWarehouse } from "react-icons/md";

const AddNewUnitPage = () => {
  const [mapData, setMapData] = useState({
    properties: {
      lat: 40.7127281,
      lon: -74.0060152,
      formatted: "New York, NY, United States of America",
    },
  });

  const { loading, storageUnit, error } = useSelector(
    (state) => state.createStorageUnit,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLocationForMap = (data) => {
    setMapData(data);
  };

  useEffect(() => {
    if (storageUnit) {
      toast.success("Created Successfully!");
      navigate(
        `/service-provider-dashboard/storagefacilities/${storageUnit?.id}`,
      );
      dispatch({ type: CREATE_STORAGE_UNIT_CLEAR });
    }

    if (error) {
      toast.error("Kindly Specify All Fields!");
      dispatch({ type: CREATE_STORAGE_UNIT_CLEAR });
    }
  }, [storageUnit, error, dispatch]);


  const addnewFacility=()=>{
    navigate('/service-provider-dashboard/addUnit')
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
        <div className="d-flex justify-content-between">
        <div >
              <p className="main-d-headings a-font">Storage Facilities</p>
            </div>
            <div className="">
              <button className="d-flex align-items-center justify-content-center gap-2 px-3" onClick={addnewFacility} style={{
                backgroundColor:'#FFCC00',
                height:'37px',
                borderRadius:'6px',
                border:'none',
              }}>
                <img src="/Images/storageFacility.png" alt="storagefacility" style={{width:'18px',height:'18px'}}></img>
              <p className="blueTextColr a-font" style={{fontSize:'14px',fontWeight:500, letterSpacing:'0.3px', margin: 0,}}> Add New Facility</p>
              </button>
            </div>
          </div>

        
          <div className="service-card p-4 mt-4">
            <div className="card-body">
              <p className="service-card-heads a-font mb-0">Create a storage facility</p>
              <div className="row mt-4">
                <div className="w-full">
                    <AddUnitForm onLocationChange={getLocationForMap} />
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-md-6">
                  <AddUnitForm onLocationChange={getLocationForMap} />
                </div>
                <div className="col-md-4">
                  <AddUnitMap mapData={mapData} />
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddNewUnitPage;
