import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import GeoApiAuto from "../../pages-components/GeoApiAuto/GeoApiAuto";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {AiFillInstagram, AiFillTwitterSquare, AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io";
import { LuLoader } from "react-icons/lu";

const Footer = () => {

  const [isLocationSet, setIsLocationSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [formData, setFormData] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const getLocationData = (data, id) => {
    // console.log(" data is " , data);
    if (!data) {
      setIsLocationSet(false);
      return setFormData((prev) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }

    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };

    // setFormData((prev) => {
    //   console.log('set form data ' , locationData.address );
    //   return {
    //     ...prev,
    //     [id]: locationData.address,
    //   };
    // });

    setFormData((prev) => {
      const updatedFormData = { ...prev, [id]: locationData.address };
      if (id === "location") setIsLocationSet(true);
      return updatedFormData;
    });

    setIsLocationSet(true);
  };

  const navigate = useNavigate();

  const submitFormHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    // console.log("submit form data " , formData);
    await waitUntilLocationIsSet();
    // console.log("submitinggggggg " , formData);

    if (!formData.location) {
      return toast.error("Location is Required.");
    }

    // console.log("out ");
    setIsLoading(false);
    localStorage.setItem("us_Storage_facility", JSON.stringify(formData));

    navigate({
      pathname: "/storages/results",
      search: `?${createSearchParams(formData)}`,
    });
  };
  const waitUntilLocationIsSet = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        console.log(isLocationSet);
        if (isLocationSet) {
          clearInterval(interval); // Stop polling
          resolve();
        }
      }, 100); // Check every 100ms
    });
  };

  return (
    <Container fluid style={{ backgroundColor: '#FFFBEA', padding: '60px' ,maxWidth:'1688px'}}>
          <Row>
        <Col md={6}>
        <p className="blueTextColr a-font" style={{fontSize:'26px', fontWeight:500}} >Find storage that's <strong>close</strong>, <strong>convenient</strong>,
        <br></br>and <strong>affordable</strong>—all in one place.</p>
        <div className="d-flex mt-5 mb-5">
                <label
                  className="d-md-none"
                  htmlFor="size"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Location
                </label>
                <div
                style={{
                  width:'65%' ,backgroundColor:'white' , 
                  paddingTop:'18px' , paddingBottom:'18px',justifyContent:"center",
                  borderRadius: '10px',
                }}>
                  <div style={{width:'100%'}} >
                      <GeoApiAuto id="location" getLocationData={getLocationData} />
                  </div>
                </div>
               
                <div
                  className=""
                  style={{ marginLeft: "-4%"}}
                >
                  <Link to="/storages/results">
                  <button
                type="button"
                onClick={submitFormHandler}
                className=""
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  backgroundColor: '#ffcc00',
                  border: 'none',
                  // borderTopRightRadius:'10px',
                  // borderBottomRightRadius:'10px',
                  borderRadius:'10px',
                  color: '#000034',
                  paddingTop: '21px',
                  paddingBottom: '21px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  zIndex: 30,
                  position: 'relative',
                }}
              >
                {isLoading ? <>Finding <LuLoader /></>: "Find Storage"}
              </button>
                  </Link>
                </div>
              </div>
        </Col>

        <Col xs={12} md={6} className="mt-4 mt-md-0 px-xl-5">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div md={3} className="px-0 px-xl-5">
            <p className="blueTextColr a-font" style={{fontSize:'26px', fontWeight:700}}>Storage Help</p >
            <ul className="list-unstyled footer-list">
              <li className="mb-2 a-font"><a className="footer-list" href="/storage-type">Types of Storage</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/storage-type">Moving Guide</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/storage-type">Storage FAQ</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/storage-type">Storage Features</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/storage-type">Storage Renter’s Guide</a></li>
            </ul>
          </div>
          <div md={3} className="px-0 px-xl-5">
          <p className="blueTextColr a-font" style={{fontSize:'26px', fontWeight:700}}>Size Guide</p >
            <ul className="list-unstyled">
              <li className="mb-2 a-font"><a className="footer-list" href="/SizingGuidPage">Small Unit</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/SizingGuidPage">Medium Unit</a></li>
              <li className="mb-2 a-font"><a className="footer-list" href="/SizingGuidPage">Large Unit</a></li>
            </ul>
          </div>
        </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between">
      <Col xs={12} md={6} className="d-flex flex-column align-items-start mt-4 mt-md-0">
      <p className="blueTextColr a-font" style={{fontSize:'26px', fontWeight:700}}>Follow Us</p>
          <div className="d-flex gap-2">
          <Link to={`http://www.instagram.com/findxstorage/`}
        style={{
          textDecoration: 'none',
        }}
        target="_blank"
        >
            <button
              className="m-2 d-flex align-items-center justify-content-center"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                backgroundColor:'#FFCC00',
                border: 'none',
                borderRadius: '10px',
                color: 'white',
                width: '44px',
                height: '44px',
              }}
            >
              <AiFillInstagram color="#000034" size={30}/>
            </button>
          </Link>
          <Link to={`http://www.facebook.com/profile.php?id=61563661539778`}
        style={{
          textDecoration: 'none',
        }}
        target="_blank"
        >
            <button
              className="m-2 d-flex align-items-center justify-content-center"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                backgroundColor:'#FFCC00',
                border: 'none',
                borderRadius: '10px',
                color: 'white',
                width: '44px',
                height: '44px',
              }}
            >
              <FaFacebookF color="#000034" size={30}/>
            </button>

            </Link>
          <Link to={`http://x.com/findxstorage/`}
        style={{
          textDecoration: 'none',
        }}
        target="_blank"
        >

            <button
              className="m-2 d-flex align-items-center justify-content-center"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                backgroundColor:'#FFCC00',
                border: 'none',
                borderRadius: '10px',
                color: 'white',
                width: '44px',
                height: '44px',
              }}
            >
              <FaXTwitter color="#000034" size={30}/>
            </button>

            </Link>
          <Link to={`http://www.linkedin.com/company/findxstorage/`}
        style={{
          textDecoration: 'none',
        }}
        target="_blank"
        >

            <button
              className="m-2 d-flex align-items-center justify-content-center"
              style={{
                fontWeight: '700',
                fontSize: '16px',
                backgroundColor:'#FFCC00',
                border: 'none',
                borderRadius: '10px',
                color: 'white',
                width: '44px',
                height: '44px',
              }}
            >
              <IoLogoLinkedin  color="#000034" size={30}/>

            </button>
            </Link>
          </div>
        </Col>

        <Col xs={12} md={6} className="d-flex flex-column px-0 px-xl-5 justify-content-center">
        <div md={3} className="d-flex align-items-center justify-content-between ps-0 ps-xl-5">
        <p className="blueTextColr a-font" style={{fontSize:'26px', fontWeight:700}}>Need Help?</p>
         <div className="d-flex">
         <input
        type="text"
        placeholder="Write us a message"
        style={{
          flex: "1",
          fontSize: "16px",
          padding: "10px 15px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          border:'none',
        }}
      />
          <button
            style={{
              fontWeight: '700',
              fontSize: '24px',
              backgroundColor: '#ffcc00',
              border: 'none',
              borderRadius: '10px',
              color: '#000034',
              paddingTop: '6px',   
              paddingBottom: '6px',
              paddingLeft: '28px', 
              paddingRight: '28px', 
            }}
          >
              Send 
          </button>
         </div>
          </div>
        </Col>
      </Row>

      <div className="mt-5" style={{ borderBottom: '1px solid #000000', margin: '10px 0' }}></div>

      <Row className="text-start mt-4">
        <Col>
          <p className="blueTextColr a-font" style={{fontSize:'22px', fontWeight:400}}>©2024 FindxStorage. All rights reserved.
          <span style={{ color: '#FFCC00' , textDecoration: 'underline'}}> Privacy Policy.</span>
          </p>
        </Col>
      </Row>
      
      </Container>
  );
};

export default Footer;
