import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { forgotPassword } from "../../../actions/userActions";
import { USER_FORGOT_PASSWORD_RESET } from "../../../constants/userConstants";
import { RiLock2Line } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
    new_password: "",
  });

  const { message, error, loading } = useSelector(
    (state) => state.userForgotPassword,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // USE EFFECT

  useEffect(() => {
    if (message) {
      navigate("/sign-in");
      toast.success(message);
      dispatch({ type: USER_FORGOT_PASSWORD_RESET });
    }
    if (error) {
      toast.error(error);
    }
  }, [message, error]);

  //   Change Input Handler
  const changeInputHandler = (event) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      };
    });
  };

  //   Form Submit Handler
  const submitFormHandler = (event) => {
    event.preventDefault();
    if (formData.email === "" || formData.new_password === "") {
      return toast.error("All Feilds are required!");
    }

    dispatch(forgotPassword(formData));
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
    <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      width: '100%',
      height: '100vh',
      backgroundColor:'#F5F6FA',
    }}>
        <div className="d-flex flex-column align-items-center justify-content-center" style={{width:'480px' , height:'434px'}}>
            <div>
              <img src="/images/blufindx.png" alt="bluefindxlogo" style={{width:'75px', height:'39px', marginBottom:'16px',}}></img>
            </div>
            <div className="text-center">
              <p className="a-font mb-0" 
            style={{color:'#202224',
              fontSize:'32px',
              fontWeight:700,
            }}
            >Forgot Password.</p>
            <p className="a-font" 
            style={{color:'#2F2F2F',
              fontSize:'16px',
              fontWeight:400,
            }}
            >Don’t worry, we can restore it for you.</p>
            </div>
            
            <div className="">
                  <form onSubmit={submitFormHandler}>
                    <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="email" className="a-font login-label">Email Address</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <MdOutlineMailOutline size={20} />
                          <input 
                              type="email"
                              name="email" 
                              id="email" 
                              onChange={changeInputHandler}
                              value={formData.email}
                              placeholder="" 
                              style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                            /> 
                      </div>
                    </div>
                    <div style={{width:'480px',marginBottom:'32px',}}>
                      <label htmlFor="password" className="a-font login-label">New Password</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <RiLock2Line size={20} />
                          <input 
                              type="password"
                              name="password" 
                              id="new_password" 
                              onChange={changeInputHandler}
                              value={formData.new_password}
                              placeholder="" 
                              style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                            /> 
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: '#ffcc00',
                          border: 'none',
                          borderRadius: '8px',
                          width: '100%',
                          height: '39px',
                          color: '#202224',
                          marginBottom: '16px',
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'center', 
                        }}
                      >
                        <span
                        className="a-font"
                          style={{
                            display: 'flex',
                            alignItems: 'center', 
                            gap: '6px',
                            fontSize: '16px',
                            fontWeight: 600, 
                          }}
                        >
                          Reset Password <RiLock2Line size={18} />
                        </span>
                      </button>
                    </div>

                </form>
                <Link to="/sign-in" className="nav-link text-dark text-center m-3">
                    <p style={{
                      color:'#ffcc00',
                      fontSize:'14px',
                      fontWeight:600,
                    }}>
                      <GrPrevious /> Back to Login screen</p>
                </Link>
            </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
