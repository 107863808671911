import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

function PromotedUnitsTable({
  promotions,
  editPromotionHandler,
  deletePromotionHandler,
}) {
  return (
    <div className="card mt-4 ">
      <div className="card-body">
        <table className="table table-hover mt-2">
          <thead className="text-center">
            <tr>
              <th scope="col" style={{ width: "10rem" }}>
                Facility/Unit Id
              </th>
              <th scope="col" style={{ width: "20rem" }}>
                Promotion Name
              </th>
              <th scope="col" className="text-nowrap">
                Total Months
              </th>
              <th scope="col" className="text-nowrap">
                Offer Months
              </th>
              <th scope="col" style={{ width: "10rem" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {promotions
              ? promotions.map((row, index) =>
                  !row.storage_unit_ids ? (
                    ""
                  ) : row.storage_unit_ids.length != 0 ? (
                    <tr key={index}>
                      <td className="d-flex  flex-column align-items-center">
                        {row?.storage_unit_ids
                          .reduce((result, units) => {
                            // Assuming you want to group by storage facility name
                            const facilityName = units.storage_facility?.name;
                            // Find the index of the facility in the result array
                            const facilityIndex = result.findIndex(
                              (facility) => facility.name === facilityName,
                            );
                            // If the facility doesn't exist in the result array, add it
                            if (facilityIndex === -1) {
                              result.push({
                                name: facilityName,
                                units: [
                                  {
                                    id: units.id,
                                    name: units.name,
                                  },
                                ],
                              });
                            } else {
                              // If the facility already exists, add the storage promotion to its array
                              result[facilityIndex].units.push({
                                id: units.id,
                                name: units.name,
                              });
                            }
                            return result;
                          }, [])
                          .map((facility, index) => {
                            return (
                              <>
                                <div key={index}>
                                  <b>{facility.name}</b>
                                </div>
                                {facility.units.map((units) => (
                                  <span>{units.name}</span>
                                ))}
                              </>
                            );
                          })}
                      </td>

                      <td className=" text-break" style={{ width: "20rem" }}>
                        {row.title}
                      </td>
                      <td>{row.total_months}</td>
                      <td>{row.offer_months}</td>
                      <td>
                        <span
                          className="border-0 bg-none text-info m-1"
                          onClick={() => editPromotionHandler(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <MdModeEdit size={25} />
                        </span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  ),
                )
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default PromotedUnitsTable;
