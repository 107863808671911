import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";

import { createRooms } from "../../../../actions/roomsActions";
import { IoCheckmark } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

const AddRoomForm = ({ id, promotions }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userLogin);
  // console.log(user);

  const validationSchema = Yup.object().shape({
    units: Yup.array().of(
      Yup.object().shape({
        name: Yup.number()
          .required("Required")
          .min(1, "Must be greater than 0"),
        size: Yup.string().required("Required"),
        per_unit_price: Yup.number()
          .required("Required")
          .min(1, "Price must be greater than 0"),
        storage_unit_type: Yup.string().required("Required"),
        description: Yup.string().required("Description is required"),
      }),
    ),
  });

  const onSubmit = (values) => {
    const formFields = values.units.map((unit) => ({
      ...unit,
      storage_facility_ids: id,
      size: Number(unit.size),
      per_unit_price: Number(unit.per_unit_price),
      promotion_ids:
        unit.attachPromotion === false ? [] : [parseInt(unit.promotion_ids)],
    }));

    sendNotificationToClient({
      business_type: "update",
      message: "New Storage Unit Created",
    });
    dispatch(createRooms(id, formFields));
  };

  const sendNotificationToClient = (order) => {
    const notification = order;
    const existingNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    existingNotifications.push(notification);
    localStorage.setItem(
      "notifications",
      JSON.stringify(existingNotifications),
    );
  };

  return (
    <Formik
      initialValues={{
        units: [
          {
            name: "",
            size: "",
            per_unit_price: "",
            is_available: false,
            description: "",
            storage_unit_type: "",
            attachPromotion: false,
            promotion_ids: [],
          },
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values , resetForm }) => (
        <Form>
          <FieldArray name="units">
            {(arrayHelpers) => (
              <div>
                {values.units.map((unit, index) => (
                  <div key={index}>
                    <div className="list-group-item py-4 border-bottom border-dark">
                      <div className="row">
                        {/* Unit Number */}
                        <div className="col-md-4 py-1">
                          <label htmlFor={`units[${index}].name`} className="a-font form-label">
                            Unit Id
                          </label>
                          <Field
                            type="number"
                            name={`units[${index}].name`}
                            className="form-input ps-3"  style={{borderRadius:'8px'}}
                            placeholder="Unit Number"
                          />
                          <ErrorMessage
                            name={`units[${index}].name`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Unit Size */}
                        <div className="col-md-3 py-1">
                          <label htmlFor={`units[${index}].size`} className="a-font form-label">
                            Size</label>
                          <Field
                            as="select"
                            name={`units[${index}].size`}
                            className="form-input ps-3"  style={{borderRadius:'8px'}}
                          >
                            <option value="">Choose size</option>
                            {[
                              10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65,
                              70, 75, 80, 85, 90, 95, 100,
                            ].map((size) => (
                              <option key={size} value={size * size}>
                                {size} x {size}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name={`units[${index}].size`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Unit Price */}
                        <div className="col-md-3 py-1">
                          <label htmlFor={`units[${index}].per_unit_price`} className="a-font form-label">
                            Unit Price
                          </label>
                          <Field
                            type="number"
                            name={`units[${index}].per_unit_price`}
                            className="form-input ps-3"  style={{borderRadius:'8px'}}
                            placeholder="Unit Price"
                          />
                          <ErrorMessage
                            name={`units[${index}].per_unit_price`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Delete Button */}
                        {/* <div className="col-md-2 py-1 d-flex justify-content-start align-items-center">
                          <button
                            type="button"
                            className="border border-0 bg-white rounded"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <MdDelete
                              size={25}
                              className="text-danger mt-0 pt-0"
                            />
                            <span className="p-2 ">Delete</span>
                          </button>
                        </div> */}
                        {/* Description */}
                        <div className="col-md-7 py-1">
                          <label htmlFor={`units[${index}].description`} className="a-font form-label">
                            Description
                          </label>
                          <Field
                            as="textarea"
                            name={`units[${index}].description`}
                            className="form-input ps-3"  style={{borderRadius:'8px'}}
                            placeholder="Description"
                          />
                          <ErrorMessage
                            name={`units[${index}].description`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Storage Unit Type */}
                        <div className="col-md-3 py-1">
                          <label htmlFor={`units[${index}].storage_unit_type`} className="a-font form-label">
                            Storage Unit Type
                          </label>
                          <Field
                            as="select"
                            name={`units[${index}].storage_unit_type`}
                            className="form-input ps-3"  style={{borderRadius:'8px'}}
                          >
                            <option value="">Choose Type</option>
                            <option value="indoor_storage">
                              Indoor Storage
                            </option>
                            <option value="outdoor">Outdoor Storage</option>
                            <option value="climate_control">
                              Climate Control
                            </option>
                          </Field>
                          <ErrorMessage
                            name={`units[${index}].storage_unit_type`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* Is Available */}
                        <div
                          data-title="Payment Area"
                          className="col-md-2 py-1 d-flex justify-content-start align-items-center "
                        >
                          <Tooltip
                            title={
                              !user.is_stripe_account_active
                                ? "Please connect your stripe account"
                                : ""
                            }
                            position="top"
                            trigger="mouseenter"
                            disabled={user.is_stripe_account_active}
                          >
                            <div className="d-flex justify-content-start align-items-center">
                              <Field
                                type="checkbox"
                                name={`units[${index}].is_available`}
                                className="form-check-input p-2 m-2"
                                disabled={!user.is_stripe_account_active}
                              />
                              <label
                                htmlFor={`units[${index}].is_available`}
                                className={`${!user.is_stripe_account_active && "a-font form-label"}`}
                              >
                                Available
                              </label>
                            </div>
                          </Tooltip>
                        </div>
                        {/* promotion */}
                        <div className="row py-2">
                          <div className="col-md-12 py-1 d-flex align-items-center">
                            <Field
                              type="checkbox"
                              id={`units[${index}].attachPromotion`}
                              name={`units[${index}].attachPromotion`}
                              className="form-check-input p-2 m-2"
                            />
                            <label
                              className="a-font form-label"
                              htmlFor={`units[${index}].attachPromotion`}
                            >
                              Attach Promotion
                            </label>
                          </div>
                          <div className="col-md-6 py-1">
                            <Field
                              name={`units[${index}].promotion_ids`}
                              id={`units[${index}].promotion_ids`}
                              as="select"
                              className={`form-select ${
                                !values.units[index].attachPromotion && "d-none"
                              }`}
                            >
                              <option className="" value="">
                                Select Promotion
                              </option>
                              {promotions.map((promotion) => (
                                <option
                                  className=""
                                  key={promotion.id}
                                  value={promotion.id}
                                >
                                  {promotion.title}
                                </option>
                              ))}
                            </Field>
                          </div>
                          {/* Promotion description */}
                          <div className="col-md-6 py-1">
                            {values.units[index].attachPromotion &&
                              promotions.map((promo) => {
                                if (
                                  promo.id ===
                                  parseInt(values.units[index].promotion_ids)
                                ) {
                                  return (
                                    <div key={promo.id}>
                                      <span className="badge mineBackgroundTextOrange m-1 p-2">
                                        Promo: {promo.value}
                                        {promo.type == "percentage_off"
                                          ? "% off"
                                          : promo.type == "dollar_off"
                                            ? "$ off "
                                            : `$ for first ${promo.offer_months} month(s)`}
                                      </span>

                                      <span className="badge mineBackgroundTextOrange m-1 p-2">
                                        Total Months: {promo.total_months}
                                      </span>
                                      <span className="badge mineBackgroundTextOrange m-1 p-2">
                                        Offer Months: {promo.offer_months}
                                      </span>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="border border-0 p-2 bg-orange text-dark rounded mt-3"
                  onClick={() =>
                    arrayHelpers.push({
                      name: "",
                      size: "",
                      per_unit_price: "",
                      is_available: false,
                      description: "",
                      storage_unit_type: "",
                      promotion_ids: [],
                      attachPromotion: false,
                    })
                  }
                >
                  Add More Unit
                </button>
              </div>
            )}
          </FieldArray>
        <div className="d-flex flex-row-reverse gap-3 mt-5 mx-3">
        <button
              type="submit"
              className="p-1 px-3 d-flex gap-2 align-items-center"
              style={{
                backgroundColor: '#FFCC00',
                borderRadius: '6px',
                border: 'none',
                width: '96px',
              }}
            >
              <IoCheckmark size={12} /> Save
            </button>

          <button
              type="button"
              className="p-1 px-3 d-flex gap-2 align-items-center"
              style={{
                backgroundColor: '#F1F4F9',
                borderRadius: '6px',
                border: 'none',
                width: '96px',
              }}
              onClick={() => resetForm()}
            >
              <RxCross1 color="black" size={12} />
              Cancel
            </button>
          
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddRoomForm;
