import React, { useEffect, useState } from "react";
// import OrdersRating from "./OrdersRating";
import OrderDetailsUserCard from "./OrderDetailsUserCrad";
const OrderDetailsUser = ({ order, id }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (order?.order) {
      // console.log(order, "~~~~~~~order");
      const calculatedTotalPrice = order.order.reduce((total, item) => {
        return total + item.unit_order.per_unit_price;
      }, 0);
      setTotalPrice(calculatedTotalPrice);
    }
  }, [order]);

  return (
    <>
      <div className="row mt-2">
        <div className="col-12">
          {order?.order.map((orderItem, index) => (
            <OrderDetailsUserCard orderItem={orderItem} id={id} key={index} />
          ))}
        </div>
        <div className="col-12 d-flex justify-content-end">
          <h5 className="fw-bold">Total Price: ${order?.total_price}</h5>
        </div>
        {!order.payment_completed && (
          <button
            className="btn text-dark mb-3 mt-3"
            onClick={() => window.open(order.payment_link, "_blank")}
          >
            Payment Due
          </button>
        )}
      </div>
    </>
  );
};

export default OrderDetailsUser;
