import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { SiVirustotal } from "react-icons/si";
import StorageFacilities from "../../StorageFacilities/StorageFacilities";
import { Card, Row, Col } from 'react-bootstrap';
import { Box, GraphUp, BoxArrowUpRight, Clock } from 'react-bootstrap-icons';

const AnalyticsSpaceCards = ({
  totalUnits,
  availableUnits,
  occupiedUnits,
  StorageUnitData,
  orders
}) => {

  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  useEffect(() => {
    if ( orders.results && Array.isArray( orders.results)) {
      const pendingOrders =  orders.results.filter((order) => order.pending === true);
      setPendingOrdersCount(pendingOrders.length);
    }
  }, [ orders]);

  // console.log("~~~~~~ " ,StorageUnitData);

  const calculateAverageRating = () => {
    let totalRatings = 0;
    let ratingCount = 0;

    StorageUnitData?.forEach((facility) => {
        if (facility.facility_ratings && facility.facility_ratings.length > 0) {
            facility.facility_ratings.forEach((rating) => {
                totalRatings += rating.facility_ratings;
                ratingCount++;
            });
        }
    });

    return ratingCount > 0 ? (totalRatings / ratingCount).toFixed(2) : "No ratings available";
};

const averageRating = calculateAverageRating(StorageUnitData);
// console.log("~~~~~~~~Average Facility Rating:", averageRating);

  return (
    <>
      {/* <div className="col-md-4 mb-2">
        <Link
          to="/service-provider-dashboard/storageUnits"
          className="card bg-gradient-1 text-dark p-3 h-100 nav-link "
        >
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Total Units</h3>
              <span>
                <SiVirustotal size={20} />
              </span>
            </div>
            <div className="mt-2">
              <h5>{totalUnits?.count} Storage Units</h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-2">
        <Link
          to="/service-provider-dashboard/availableStorageUnits"
          className="card bg-gradient-2  text-dark p-3 h-100 nav-link"
        >
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Available Units</h3>
              <span>
                <SiVirustotal size={20} />
              </span>
            </div>
            <div className="mt-2">
              <h5>{availableUnits?.count} Storage Units</h5>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-md-4 mb-2">
        <Link
          to="/service-provider-dashboard/occupiedStorageUnits"
          className="card bg-gradient-3 text-dark p-3 h-100 nav-link"
        >
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Occupied Units</h3>
              <span>
                <SiVirustotal size={20} />
              </span>
            </div>
            <div className="mt-2">
              <h5>{occupiedUnits?.count} Storage Units</h5>
            </div>
          </div>
        </Link>
      </div> */}

    <Row className="g-4">
  
      <Col xs={12} md={3}>
        <div className="p-3 service-card position-relative" >
          <Row className="align-items-center">
            <Col xs={8}>
              <p className="n-font dashboard-card-content mb-0">Total Units</p>
              <p className="n-font dashboard-card-figure mb-0 my-2">{totalUnits?.count}</p>
              <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{occupiedUnits?.count} Units </span> occupied
                </p>
                <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{availableUnits?.count} Units </span> available
                </p>
            </Col>
          </Row>
          <div className="position-absolute"
           style={{
            top: '16px',
            right: '16px',
            width: '60px',
            height: '60px',
            // backgroundColor: '#4AD991',
            // borderRadius: '23px',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
          }}>
          <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 26.3164L29.9005 33.7645C30.0394 33.8447 30.1851 33.9026 30.3333 33.9394V48.3846L17.9201 41.0385C17.3498 40.701 17 40.0875 17 39.4248V26.3164ZM47.0003 26.1184V39.4248C47.0003 40.0875 46.6505 40.701 46.0803 41.0385L33.667 48.3846V33.8129C33.6972 33.7978 33.7272 33.7816 33.7569 33.7645L47.0003 26.1184V26.1184Z" fill="#8280FF"/>
        <path opacity="0.499209" fill-rule="evenodd" clip-rule="evenodd" d="M17.4043 22.7014C17.5619 22.5024 17.7608 22.3343 17.9926 22.2108L31.1176 15.2201C31.6686 14.9266 32.3295 14.9266 32.8805 15.2201L46.0055 22.2108C46.1843 22.306 46.3434 22.4277 46.4791 22.5697L32.089 30.8778C31.9943 30.9325 31.9071 30.995 31.8276 31.064C31.7481 30.995 31.6609 30.9325 31.5662 30.8778L17.4043 22.7014Z" fill="#8280FF"/>
        <path opacity="0.21" fill-rule="evenodd" clip-rule="evenodd" d="M2 32V39C2 51.7025 12.2975 62 25 62H32H39C51.7025 62 62 51.7025 62 39V32V25C62 12.2975 51.7025 2 39 2H32H25C12.2975 2 2 12.2975 2 25V32Z" fill="#8280FF"/>
        </svg>

          </div>
        </div>
      </Col>


      <Col xs={12} md={3}>
      <div className="p-3 h-100 service-card position-relative">
          <Row className="align-items-center">
          <Col xs={8}>
              <p className="n-font dashboard-card-content mb-0">Total Orders</p>
              <p className="n-font dashboard-card-figure mb-0 my-2">{orders?.count}</p>
              {/* <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{occupiedUnits?.count} Units </span> occupied
                </p>
                <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{availableUnits?.count} Units </span> available
                </p> */}
            </Col>
          </Row>
          <div
            className=" position-absolute"
            style={{
              top: '16px',
              right: '16px',
              width: '60px',
              height: '60px',
              // backgroundColor: '#4AD991',
              // borderRadius: '23px',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.21" d="M0 23C0 10.2975 10.2975 0 23 0H37C49.7025 0 60 10.2975 60 23V37C60 49.7025 49.7025 60 37 60H23C10.2975 60 0 49.7025 0 37V23Z" fill="#4AD991"/>
<path d="M19.1111 40.8889H42.4444C43.3036 40.8889 44 41.5853 44 42.4444C44 43.3036 43.3036 44 42.4444 44H17.5556C16.6964 44 16 43.3036 16 42.4444V17.5556C16 16.6964 16.6964 16 17.5556 16C18.4147 16 19.1111 16.6964 19.1111 17.5556V40.8889Z" fill="#4AD991"/>
<path opacity="0.5" d="M24.9131 34.175C24.3255 34.8017 23.3411 34.8335 22.7143 34.2459C22.0876 33.6583 22.0558 32.6739 22.6434 32.0472L28.4767 25.8249C29.045 25.2188 29.9893 25.1662 30.6213 25.7056L35.2253 29.6343L41.224 22.0361C41.7563 21.3618 42.7345 21.2467 43.4088 21.779C44.0831 22.3114 44.1982 23.2895 43.6658 23.9638L36.6658 32.8305C36.1191 33.5231 35.1063 33.6227 34.4351 33.0499L29.7311 29.0358L24.9131 34.175Z" fill="#4AD991"/>
</svg>

          </div>
        </div>
      </Col>

      <Col xs={12} md={3}>
      <div className="p-3 h-100 service-card position-relative">
          <Row className="align-items-center">
          <Col xs={8}>
              <p className="n-font dashboard-card-content mb-0">Total Pending</p>
              <p className="n-font dashboard-card-figure mb-0 my-2">{pendingOrdersCount}</p>
              {/* <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{occupiedUnits?.count} Units </span> occupied
                </p>
                <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{availableUnits?.count} Units </span> available
                </p> */}
            </Col>
          </Row>
          <div
            className=" position-absolute"
            style={{
              top: '16px',
              right: '16px',
              width: '60px',
              height: '60px',
              // backgroundColor: '#FEC53D',
              // borderRadius: '23px',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.21" d="M0 23C0 10.2975 10.2975 0 23 0H37C49.7025 0 60 10.2975 60 23V37C60 49.7025 49.7025 60 37 60H23C10.2975 60 0 49.7025 0 37V23Z" fill="#FEC53D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 24.3164L27.9005 31.7645C28.0394 31.8447 28.1851 31.9026 28.3333 31.9394V46.3846L15.9201 39.0385C15.3498 38.701 15 38.0875 15 37.4248V24.3164ZM45.0003 24.1184V37.4248C45.0003 38.0875 44.6505 38.701 44.0803 39.0385L31.667 46.3846V31.8129C31.6972 31.7978 31.7272 31.7816 31.7569 31.7645L45.0003 24.1184Z" fill="#FEC53D"/>
        <path opacity="0.499209" fill-rule="evenodd" clip-rule="evenodd" d="M15.4043 20.7014C15.5619 20.5024 15.7608 20.3343 15.9926 20.2108L29.1176 13.2201C29.6686 12.9266 30.3295 12.9266 30.8805 13.2201L44.0055 20.2108C44.1843 20.306 44.3434 20.4277 44.4791 20.5697L30.089 28.8778C29.9943 28.9325 29.9071 28.995 29.8276 29.064C29.7481 28.995 29.6609 28.9325 29.5662 28.8778L15.4043 20.7014Z" fill="#FEC53D"/>
        </svg>


          </div>
        </div>
      </Col>

      <Col xs={12} md={3}>
      <div className="p-3 h-100 service-card position-relative">
          <Row className="align-items-center">
            <Col xs={8}>
              <p className="n-font dashboard-card-content mb-0">Performance</p>
              <p className="n-font dashboard-card-figure mb-0 my-2">{averageRating}</p>
              {/* <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{occupiedUnits?.count} Units </span> occupied
                </p>
                <p className="n-font dashboard-card-p mb-0" style={{color:'#202224'}}>
                <span style={{color:'#00B69B'}}>{availableUnits?.count} Units </span> available
                </p> */}
            </Col>
          </Row>
          <div
            className=" position-absolute"
            style={{
              top: '16px',
              right: '16px',
              width: '60px',
              height: '60px',
              // backgroundColor: '#FF9066',
              // borderRadius: '23px',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M0 30V37C0 49.7025 10.2975 60 23 60H30H37C49.7025 60 60 49.7025 60 37V30V23C60 10.2975 49.7025 0 37 0H30H23C10.2975 0 0 10.2975 0 23V30Z" fill="#FF9066"/>
          <path opacity="0.78" fill-rule="evenodd" clip-rule="evenodd" d="M28.6319 23.8088C28.6519 23.5483 28.8692 23.3472 29.1304 23.3472H29.5482C29.8051 23.3472 30.0202 23.5418 30.0458 23.7974L30.6674 30.0138L35.0821 32.5365C35.2379 32.6256 35.3341 32.7912 35.3341 32.9707V33.3592C35.3341 33.6889 35.0206 33.9283 34.7025 33.8416L28.3994 32.1226C28.168 32.0595 28.014 31.841 28.0324 31.6018L28.6319 23.8088Z" fill="#FF9066"/>
          <path opacity="0.901274" fill-rule="evenodd" clip-rule="evenodd" d="M22.7214 14.9843C22.4573 14.6696 21.9474 14.7901 21.852 15.1897L20.2186 22.0379C20.1409 22.3635 20.3989 22.672 20.7332 22.6531L27.778 22.2539C28.1889 22.2306 28.3972 21.7485 28.1327 21.4333L26.3313 19.2864C27.4962 18.8883 28.7316 18.6804 30 18.6804C36.2592 18.6804 41.3333 23.7545 41.3333 30.0137C41.3333 36.2729 36.2592 41.347 30 41.347C23.7408 41.347 18.6667 36.2729 18.6667 30.0137C18.6667 28.9629 18.809 27.9338 19.0864 26.9447L16.5188 26.2245C16.1808 27.4296 16 28.7006 16 30.0137C16 37.7457 22.268 44.0137 30 44.0137C37.732 44.0137 44 37.7457 44 30.0137C44 22.2817 37.732 16.0137 30 16.0137C28.055 16.0137 26.2027 16.4103 24.5194 17.1271L22.7214 14.9843Z" fill="#FF9066"/>
          </svg>

          </div>
        </div>
      </Col>
    </Row>

    </>
  );
};

export default AnalyticsSpaceCards;
