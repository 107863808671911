import React, { useEffect, useState } from "react";
import { FaWarehouse } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import StorageUnitsTable from "../ServiceProviderDashboardComponents/StorageUnitsPageComponents/StorageUnitsTable";
import Spinner from "../../../shared-components/Spinner/Spinner";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import StorageUnitsRoomsTable from "../ServiceProviderDashboardComponents/StorageProviderDetailsPageComponents/StorageUnitRoomsTable";
import StorageUnitsFilter from "../../../shared-components/Filter/StorageUnitsFilter";
import StorageUnitPagination from "../../../shared-components/Filter/StorageUnitPagination";
import { useLocation } from "react-router-dom";
import {
  getAllStorageUnits,
  getTotalStorageUnits,
  getAvailableStorageUnits,
  getOccupiedStorageUnits,
  getDistinctiveStorageUnits,
} from "../../../actions/storageUnitActions";
import { getDistinctiveStorageFacilities } from "../../../actions/usStorageFacilityActions";
import { LuWarehouse } from "react-icons/lu";
import { color } from "@cloudinary/url-gen/qualifiers/background";
import AnalyticsSpaceCards from "../ServiceProviderDashboardComponents/AnalyticsSpaceCards";
import AnalyticsMapAndChart from "../ServiceProviderDashboardComponents/AnalyticsMapAndChart";
import AnalyticsRecentUnitsAndChart from "../ServiceProviderDashboardComponents/AnalyticsRecentUnitsAndChart";
import AnalyticsReviewsTableAndUnitStatus from "../ServiceProviderDashboardComponents/AnalyticsReviewsTableAndUnitStatus";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min";

const EmployeesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const { loading, storageUnits } = useSelector(
    (state) => state.getTotalStorageUnit,
  );
  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    is_occupied: false,
    ordering: "-created_at",
    page: 1,
    name: "",
    storage_facility__name: "",
  });
  const { distinctiveStorageUnits } = useSelector(
    (state) => state.getDistinctiveStorageUnits,
  );
  const { storageFacility } = useSelector(
    (state) => state.getDistinctiveStorageFacilities,
  );

  const { loading: totalStorageLoading, storageUnits: totalStorageUnits } =
    useSelector((state) => state.getTotalStorageUnit);

  const { loading: allStorageLoading, storageUnits: allStorageUnits } =
    useSelector((state) => state.getAllStorageUnits);

  const { loading: ordersLoading, orders } = useSelector(
    (state) => state.allOrdersAdmin,
  );

  const { loading: availableLoading, availableStorageUnits } = useSelector(
    (state) => state.getAvailableStorageUnit,
  );

  const { loading: occupiedLoading, occupiedStorageUnits } = useSelector(
    (state) => state.getOccupiedStorageUnit,
  );

  const filterOptions = [
    { value: "is_available", label: "Is Available" },
    { value: "is_occupied", label: "Is Occupied" },
    { value: "ordering", label: "Ordering" },
  ];

  const handleFilterChange = (selectedOptions) => {
    const updatedFilters = { ...selectedFilters };
    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    handleSearch();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDistinctiveStorageUnits());
    dispatch(getDistinctiveStorageFacilities());
  }, []);

  const handleSearch = () => {
    switch (lastPart) {
      case "storageUnits":
        dispatch(getTotalStorageUnits(selectedFilters));
        break;
      case "availableStorageUnits":
        dispatch(getAvailableStorageUnits(selectedFilters));
        break;
      case "occupiedStorageUnits":
        dispatch(getOccupiedStorageUnits(selectedFilters));
        break;
    }
  };

  const handlePageChange = (pageNumber) => {
    setSelectedFilters({
      ...selectedFilters,
      page: pageNumber,
    });

    switch (lastPart) {
      case "storageUnits":
        dispatch(
          getTotalStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      case "availableStorageUnits":
        dispatch(
          getAvailableStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      case "occupiedStorageUnits":
        dispatch(
          getOccupiedStorageUnits({ ...selectedFilters, page: pageNumber }),
        );
        break;
      default:
        break;
    }
  };

  const updateFiltersAndSearch = (updatedFilters) => {
    setSelectedFilters(updatedFilters);
    dispatch(getTotalStorageUnits(updatedFilters));
  };

  const storageFacilityOptions = Array.isArray(allStorageUnits?.results)
    ? allStorageUnits.results.map((facility) => ({
        value: facility.id,
        label: facility.name,
      }))
    : [];

  // console.log("~~storage facility options", storageFacilityOptions?.length);
  // console.log("~~storage units", storageUnits?.results);
  const handleDropdownItemClick = (id) => {
    // Implement your logic here for what should happen when a dropdown item is clicked
    console.log("Selected storage facility ID:", id);
    navigate(`/service-provider-dashboard/addRoom/${id}`);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    // Optionally, reset after some time or other conditions
    setTimeout(() => setIsClicked(false), 300); // Reset after 300ms
  };
  return (
    <>
       <div className="d-flex justify-content-between">
        <div >
          <p className="main-d-headings a-font">Storage Units</p>
        </div>
        <div >
            <button
              type="button"
              className="d-flex align-items-center justify-content-center gap-2 px-3 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                backgroundColor:'#FFCC00',
                height:'37px',
                borderRadius:'6px',
                border:'none',
              }}    
              onClick={handleClick}
            >
              <img src="/Images/storageFacility.png" alt="storagefacility" style={{width:'18px',height:'18px'}}></img>
              <p className="mb-0 a-font" style={{fontSize:'14px',fontWeight:500,color:'#000034'}}>Add New Unit</p>
            </button>
            <ul
              className="dropdown-menu"
              style={{ backgroundColor: "#ffcc00", color: "black" }}
            >
              {storageFacilityOptions.map((option, index) => (
                <li key={index}>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                      handleDropdownItemClick(option.value);
                    }}
                  >
                    {option.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
      </div>
      {loading ? (
        <Spinner />
      ) : storageFacilityOptions?.length === 0 ? ( //  checking if facility is there, cant create unit without facility
        <div>
          <div className="mt-5 text-center">
            <LuWarehouse size={70} />
            <h1 className="mt-2">No Storage Facility Found</h1>
            <p className="mt-2">
              To Create a Storage Unit, You need to create a facility store
              first. Click here to create one.
            </p>
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
        </div>
      ) : (
        ///// if facilities are there but then check about units if not there , giving add new unit button with drop down.
        <div>
          {loading ? (
            <Spinner />
          ) : storageUnits?.count === 0 ? (
            <div className="mt-5 text-center">
              <FaWarehouse size={70} />
              <h1 className="mt-2">No Storage Unit Found</h1>
              <p className="mt-2">
                It looks like there are no storage unit in any facility yet.
                Click here to create a new one.
              </p>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-danger dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: isClicked ? "#e0a800" : "#ffc107",
                    color: isClicked ? "white" : "black",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  ADD NEW UNIT
                </button>
                <ul
                  className="dropdown-menu"
                  style={{ backgroundColor: "#ffc107", color: "black" }}
                >
                  {storageFacilityOptions.map((option, index) => (
                    <li key={index}>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default link behavior
                          handleDropdownItemClick(option.value);
                        }}
                      >
                        {option.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div className="service-card p-4 mt-4">
                <div className="card-body">
                  <StorageUnitsFilter
                    selectedFilters={selectedFilters}
                    onFilterChange={handleFilterChange}
                    onUpdateFilters={updateFiltersAndSearch}
                    distinctiveStorageUnits={distinctiveStorageUnits}
                    storageFacility={storageFacility}
                  />
                  {loading ? (
                    <Spinner />
                  ) : (
                    <StorageUnitsRoomsTable storageUnits={storageUnits} />
                  )}
                  <StorageUnitPagination
                    storageUnits={storageUnits}
                    selectedFilters={selectedFilters}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EmployeesPage;
