import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";

import { cartUpdateItems } from "../../actions/cartActions";

const CartList = ({
  item,
  handleDataFromChild,
  unselected,
  blockCheckoutArray,
  setBlockCheckoutArray,
  arrIndex,
}) => {
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedPromotion, setSelectedPromotion] = useState({
    id: 0,
    title: "Select Promotion",
  });
  const [finalPrice, setFinalPrice] = useState(item.per_unit_price);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(today.getDate() + 2);
    const maxDateString = dayAfterTomorrow.toISOString().split("T")[0];

    setMinDate(formattedDate);
    setMaxDate(maxDateString);
  }, []);

  useEffect(() => {
    if (
      selectedPromotion.id != 0 &&
      selectedMonth >= selectedPromotion.total_months
    ) {
      let price;
      if (selectedPromotion.type == "percentage_off") {
        price =
          selectedMonth * item.per_unit_price -
          selectedPromotion.offer_months *
            ((selectedPromotion.value / 100) * item.per_unit_price);
      } else if (selectedPromotion.type == "dollar_off") {
        price =
          selectedPromotion.offer_months *
            (item.per_unit_price - selectedPromotion.value) +
          (selectedMonth - selectedPromotion.offer_months) *
            item.per_unit_price;
      } else if (selectedPromotion.type == "initial_discount_price") {
        price =
          (selectedMonth - selectedPromotion.offer_months) *
            item.per_unit_price +
          selectedPromotion.offer_months * selectedPromotion.value;
      }
      price = price.toFixed(2);
      setFinalPrice(price);
    } else {
      if (isNaN(selectedMonth)) {
        setFinalPrice(item.per_unit_price);
      } else {
        const items_price=(item.per_unit_price * selectedMonth).toFixed(2);
        setFinalPrice(items_price);
      }
    }
    if (blockCheckoutArray.length != 0) {
      setBlockCheckoutArray((prevState) => {
        let newArr = [...prevState];
        if (finalPrice < 0) {
          newArr[arrIndex].blockCheckout = true;
        } else {
          newArr[arrIndex].blockCheckout = false;
        }

        return newArr;
      });
    }
    const newData = {
      storage_unit_ids: item.id,
      promotion_id: selectedPromotion.id,
      total_price: (item.per_unit_price * selectedMonth).toFixed(2),
      final_price: finalPrice,
      amount: parseFloat(finalPrice),
      starting_date: selectedDates[item.id]
        ? selectedDates[item.id].starting_date
        : "",
      months: selectedMonth,
    };
    // console.log(newData);
    handleDataFromChild(newData);
  }, [selectedDates, selectedMonth, selectedPromotion, finalPrice]);

  const removeItem = (id) => {
    dispatch(cartUpdateItems(id));
    toast.success("Removed Successfully.");
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    const newData = { id: item.id, starting_date: newDate };
    setSelectedDates({
      ...selectedDates,
      [item.id]: newData,
    });
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value);
    setSelectedMonth(newMonth);
  };
  const handlePromotionChange = (event) => {
    const promo_index = parseInt(event.target.value);
    if (promo_index === -1) {
      setSelectedPromotion({ id: 0 });
    } else {
      setSelectedPromotion(item.storage_promotions[promo_index]);
    }
  };

  const selectedObj = unselected.find(
    (obj) => obj.storage_unit_ids === item.id,
  );

  const isStartingDateInvalid = selectedObj && selectedObj.starting_date === "";
  const isMonthsInvalid = selectedObj && selectedObj.months === 0;

  return (
    <>
      <li key={item.id} className="list-group-item p-3">
        <div className="d-flex row">
          {/* Image , Name , Description */}
          <div className="col-2 text-center">
            <img
              src="/images/small.png"
              className="img-fluid rounded"
              alt="indoor"
            />
            <br />
            <br />
            <h5 className="text-break">{item.name}</h5>
          </div>

          {/* Second half container */}
          <div
            className={`col-10 row ${isStartingDateInvalid || isMonthsInvalid ? "is-invalid" : ""}`}
          >
            {/* Start date, Duration, Delete button */}
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start">
                {/* Start Date */}
                <div
                  className={`d-flex justify-content-center align-items-center me-3 my-1  ${
                    isStartingDateInvalid ? "is-invalid" : ""
                  }`}
                  style={{ maxWidth: "300px", gap: "10px" }}
                >
                  <label htmlFor="Starting_date" className="text-nowrap">
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="starting_date"
                    className={`form-select ${isStartingDateInvalid ? "is-invalid" : ""}`}
                    value={selectedDates.starting_date}
                    onChange={handleDateChange}
                    required
                    style={{
                      height: "fit-content",
                      maxWidth: "200px",
                    }}
                    min={minDate}
                    max={maxDate}
                  />
                  {/* {isStartingDateInvalid && (
                  <div className="invalid-feedback">
                    Please select a valid date.
                  </div>
                )} */}
                </div>

                {/* Duration */}
                <div
                  className={`form-group d-flex justify-content-center align-items-center my-1 ${
                    isMonthsInvalid ? "is-invalid" : ""
                  }`}
                  style={{ maxWidth: "300px", gap: "10px" }}
                >
                  <label htmlFor="duration">Duration </label>
                  <select
                      id="months"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      className={`form-select ${isMonthsInvalid ? "is-invalid" : ""}`}
                      aria-label="Default select example"
                    >
                      <option value={0}>Select Month</option>
                      {Array.from({ length: 12 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1} Month{index === 0 ? "" : "s"}
                        </option>
                      ))}
                    </select>
                  {/* {isMonthsInvalid && (
                  <div className="invalid-feedback">
                    Please select a valid month.
                  </div>
                )} */}
                </div>
              </div>
              <button
                style={{ color: "red", backgroundColor: "white" }}
                className="btn ms-2"
                onClick={() => removeItem(item.id)}
              >
                <MdDelete size={25} className=" " />
              </button>
            </div>

            {/* Promotion */}
            {item.storage_promotions.length != 0 ? (
              <div className="col-12 my-2 d-flex justify-content-start align-items-start">
                <label htmlFor="promotion" className="me-2">
                  Promotion
                </label>
                <div className="col-9">
                  <select
                    id="promotion"
                    onChange={(e) => handlePromotionChange(e)}
                    className={`form-select  ${finalPrice < 0 ? " text-danger" : ""}`}
                    aria-label="Default select example"
                  >
                    <option value={-1}>Select Promotion</option>
                    {item.storage_promotions.map((promo, index) => (
                      <option key={index} value={index}>
                        {promo.title}
                      </option>
                    ))}
                  </select>
                  {finalPrice < 0 ? (
                    <span className="text-danger">
                      ~cannot apply this promotion
                    </span>
                  ) : (
                    ""
                  )}
                  {selectedPromotion.id === 0 ? (
                    ""
                  ) : (
                    <>
                      <div className="my-2">
                        <span className="badge mineBackgroundTextOrange m-1 p-2">
                          {`Promo: ${selectedPromotion.value}${
                            selectedPromotion.type == "percentage_off"
                              ? "% off "
                              : selectedPromotion.type == "dollar_off"
                                ? "$ off"
                                : `$ for first ${selectedPromotion.offer_months} month(s)`
                          }`}
                        </span>
                        <span className="badge mineBackgroundTextOrange m-1 p-2">
                          Total Months: {selectedPromotion.total_months}
                        </span>
                        <span className="badge mineBackgroundTextOrange m-1 p-2">
                          Offer Months: {selectedPromotion.offer_months}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Price */}
            <div className="col-12  d-flex justify-content-between align-items-center my-2">
              <div className="col-5 ">
                <span className="h6">Unit Price: ${item.per_unit_price} </span>
                per month
              </div>
              <div className="col-5 ">
                <span className={`h6  ${finalPrice < 0 ? " text-danger" : ""}`}>
                  Final Price: ${finalPrice}
                </span>
                {/* per month */}
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default CartList;
