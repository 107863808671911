import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";

import {
  deleteOrder,
  deleteOrderAdmin,
  getAllOrders,
} from "../../../actions/ordersActions";
import { requestPayout } from "../../../actions/ordersActions";
import PayoutModal from "./PayoutModal";
import { FaCircle } from "react-icons/fa";
import '../Home.css';

const HomeOrdersTableServiceProvider = ({ orders }) => {
  const { info } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrderAdmin(id));
    dispatch(getAllOrders());
    navigate("/storage-provider-dashboard");
  };

  const [showModal, setShowModal] = useState(false);
  const [orderID, setOrderID] = useState(null);

  const modalOpener = (id) => {
    setOrderID(id);
    setShowModal(true);
  };
  const handlePayout = (flag1, flag2) => {
    // console.log(flag1, " ~~~ ", flag2, " ", orderID);
    dispatch(requestPayout(flag1, flag2, orderID));
    setShowModal(false);
  };
  // console.log("ordershome tav ~~~  ", orders);

  return (
    <>
      {orders?.count > 0 ? (
        <div className="table-responsive">
          <table className="table mt-4">
            <thead className="text-center">
              <tr className="">
                <th className="order-table-content bg-gray p-2" style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} scope="col">ID</th>
                <th className="order-table-content bg-gray p-2" scope="col">FACILITY</th> 
                <th className="order-table-content bg-gray p-2" scope="col">STORAGE UNIT</th>
                <th className="order-table-content bg-gray p-2" scope="col">PRICE</th>
                <th className="order-table-content bg-gray p-2" scope="col">SLUG</th>
                <th className="order-table-content bg-gray p-2" scope="col">STARTING DATE</th>
                <th className="order-table-content bg-gray p-2" scope="col">ENDING DATE</th>
                <th className="order-table-content bg-gray p-2" scope="col">Status</th>
                <th className="order-table-content bg-gray p-2" style={{borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}} scope="col"></th>
              </tr>
            </thead>
            <tbody className="text-center">
              {orders &&
                orders.results.map((order) => (
                  <tr key={order?.id}>
                    <td className="order-table-bcontent p-4">{order?.id}</td>
                    <td className="order-table-bcontent p-4">{order?.unit_order.storage_facility.id}</td>
                    <td className="order-table-bcontent p-4">{order?.unit_order?.name}</td>
                    <td className="order-table-bcontent p-4">${order?.order?.total_price}</td>
                    <td className="order-table-bcontent p-4">{order?.order?.slug}</td>
                    <td className="order-table-bcontent p-4">{order?.starting_date}</td>
                    <td className="order-table-bcontent p-4">{order?.ending_date}</td>
                    <td className="p-4">
                      {order?.completed ? (
                         <span 
                         style={{
                             fontWeight: '500',
                             fontSize: '12px',
                             backgroundColor: '#D9F7E866',
                             borderRadius: '118px',
                             color: '#5CDD9C',
                             padding: '2px 10px',
                             display: 'inline-flex',         
                             alignItems: 'center',    
                             justifyContent: 'center', 
                             border:'1px solid #5CDD9C',
                             gap:'3px',
                           }}>
                             <FaCircle size={6}></FaCircle>
                         Completed
                       </span>
                      ) : (
                        <span 
                        style={{
                            fontWeight: '500',
                            fontSize: '12px',
                            backgroundColor: '#FFDED166',
                            borderRadius: '118px',
                            color: '#FF9871',
                            padding: '2px 10px',
                            display: 'inline-flex',         
                            alignItems: 'center',    
                            justifyContent: 'center', 
                            border:'1px solid #FF9871',
                            gap:'3px',
                          }}>
                            <FaCircle size={6}></FaCircle>
                        In Progress
                      </span>
                      )}
                    </td>
                                    
                    <td className="p-4">
                      <button
                      type="button"
                      onClick={() => navigate(`/orders/${order.id}`)}
                      placeholder="Search..."
                      className="px-3"
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '4.5px',
                        height:'28px',
                        color: '#000034',
                      }}
                    >
                      View Details
                    </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <PayoutModal
            showModal={showModal}
            setShowModal={setShowModal}
            handlePayout={handlePayout}
            orderID={orderID}
          />
        </div>
      ) : (
        <p className="fw-bold m-5">No Orders Present</p>
      )}
    </>
  );
};

export default HomeOrdersTableServiceProvider;
