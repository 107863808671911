import Navbar from "../shared-components/Navbar/Navbar";
import Footer from "../shared-components/footer/Footer";
import CookiesPupup from "../shared-components/CookiesPopup/CookiesPupup";
import { TermsAndConditions } from "../shared-components/TermsAndConditions/TermsAndConditions";
import { useSelector } from "react-redux";
import ProviderNavbar from "../shared-components/Navbar/ProviderNavbar";
const Layout = ({ children }) => {

  const { info } = useSelector((state) => state.userInfo);
  
  return (
    <div>
      {info?.is_service_provider ? <ProviderNavbar /> : <Navbar />}
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
      <TermsAndConditions />
      <CookiesPupup />
      <Footer />
    </div>
  );
};

export default Layout;
