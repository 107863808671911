import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaWarehouse } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import AddRoomForm from "../ServiceProviderDashboardComponents/AddNewRoomPageComponents/AddRoomForm";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { CREATE_ROOMS_CLEAR } from "../../../constants/roomsConstants";
import { API_URL } from "../../../actions/userActions";
import { LuWarehouse } from "react-icons/lu";

const AddNewRoomPage = () => {
  const { user } = useSelector((state) => state.userLogin);
  const accessToken = user.access;
  const { id } = useParams();
  const navigate = useNavigate();
  
  const { loading, rooms, error } = useSelector((state) => state.createRooms);
  const dispatch = useDispatch();

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    if (rooms) {
      toast.success("Created successfully!");
      navigate(`/service-provider-dashboard/storagefacilities/${id}`);
      dispatch({ type: CREATE_ROOMS_CLEAR });
    }

    if (error) {
      toast.error("Something went wrong.");
      // dispatch({ type: CREATE_ROOMS_CLEAR });
    }
  }, [rooms, error]);

  useEffect(() => {
    axios
      .get(`${API_URL}/promotions/get_pormotions/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setPromotions(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-3">
          <div className="d-flex align-items-center">
            <p className="main-d-headings a-font mb-0">Storage Units</p>
          </div>
          <div className="mt-4">
            <div className="service-card p-4 mt-4">
              <div className="card-body">
              <p className="service-card-heads a-font mb-0">Add storage unit</p>
                <AddRoomForm id={id} promotions={promotions} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNewRoomPage;
