import React, { useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import { FaWarehouse } from "react-icons/fa";
import AnalyticsSpaceCards from "../ServiceProviderDashboardComponents/AnalyticsSpaceCards";
import AnalyticsMapAndChart from "../ServiceProviderDashboardComponents/AnalyticsMapAndChart";
import AnalyticsRecentUnitsAndChart from "../ServiceProviderDashboardComponents/AnalyticsRecentUnitsAndChart";
import AnalyticsReviewsTableAndUnitStatus from "../ServiceProviderDashboardComponents/AnalyticsReviewsTableAndUnitStatus";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useState } from "react";
import Select from "react-select";
import {
  getAllStorageUnits,
  getTotalStorageUnits,
  getAvailableStorageUnits,
  getOccupiedStorageUnits,
} from "../../../actions/storageUnitActions";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";
import { getAllOrdersAdmin } from "../../../actions/ordersActions";
import HomeOrdersTableServiceProvider from "../../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Pagination from "react-js-pagination";
import { LuWarehouse } from "react-icons/lu";
import { Card, Row, Col } from 'react-bootstrap';
import { Box, GraphUp, BoxArrowUpRight, Clock } from 'react-bootstrap-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaChevronDown } from 'react-icons/fa';
import './StorageUnitPage.css';
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const AnalyticsPage = () => {
  const dispatch = useDispatch();

  const { loading, storageUnits } = useSelector(
    (state) => state.getAllStorageUnits,
  );

  const { loading: ordersLoading, orders } = useSelector(
    (state) => state.allOrdersAdmin,
  );

  const { loading: totalLoading, storageUnits: totalUnits } = useSelector(
    (state) => state.getTotalStorageUnit,
  );
  const { loading: availableLoading, availableStorageUnits } = useSelector(
    (state) => state.getAvailableStorageUnit,
  );
  const { loading: occupiedLoading, occupiedStorageUnits } = useSelector(
    (state) => state.getOccupiedStorageUnit,
  );

  const {
    loading: storageFacilities,
    error,
    storageFacilties,
  } = useSelector((state) => state.usStorageFacilities);

  const [selectedFilters, setSelectedFilters] = useState({
    is_available: false,
    is_occupied: false,
    ordering: "-created_at",
  });

  useEffect(() => {
    dispatch(getAllStorageUnits());
    dispatch(getTotalStorageUnits(selectedFilters));
    dispatch(getAvailableStorageUnits(selectedFilters));
    dispatch(getOccupiedStorageUnits(selectedFilters));
  }, [dispatch]);

  const [selectedData, setSelectedData] = useState({
    pending: false,
    completed: false,
    starting_date: "",
    ending_date: "",
    search: "",
    page: 1,
  });
  const options = [
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setSelectedData({
      ...selectedData,
      pending: selectedOptions.some((option) => option.value === "pending"),
      completed: selectedOptions.some((option) => option.value === "completed"),
    });
  }, [selectedOptions]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };

  const handleFilter = () => {
    dispatch(getAllOrdersAdmin({ ...selectedData, page: 1 }));
  };

  // const handleDateChange = (e) => {
  //   const name = e.target.name;
  //   let value = e.target.value;

  //   if (name === "starting_date" || name === "ending_date") {
  //     const date = new Date(value);
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const day = String(date.getDate()).padStart(2, "0");
  //     value = `${year}-${month}-${day}`;
  //   }

  //   setSelectedData({
  //     ...selectedData,
  //     [name]: value,
  //   });
  // };

  const handleDateChange = (date, name) => {
    if (name === "starting_date" || name === "ending_date") {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        // Update state or handle the formatted date as needed
        setSelectedData((prevData) => ({
            ...prevData,
            [name]: formattedDate,
        }));
    }
};


  useEffect(() => {
    dispatch(getAllOrdersAdmin(selectedData));
  }, [dispatch, selectedData.page]);

  const handleTextChange = (e) => {
    setSelectedData({
      ...selectedData,
      search: e.target.value,
    });
  };

  const handlePaginationChange = (pageNumber) => {
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };

  const handlePagination = (str) => {
    var url = null;
    if (str === "next") {
      url = orders.next;
    } else {
      url = orders.previous;
    }
    const urlObject = new URL(url);
    const pageParam = urlObject.searchParams.get("page");
    const pageNumber = parseInt(pageParam, 10);
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };
  // console.log("Storage Facilities ", storageUnits?.results);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '0.6px solid #D5D5D5',
      borderRadius: '4px',
      height: '28px', 
      minHeight: '28px', 
      '&:hover': {
        borderColor: '#D5D5D5',
      },
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: '14px', 
      display: 'flex',
      alignItems: 'center', 
      marginBottom: 'auto', 
    }),
    multiValue: (base) => ({
      ...base,
      // backgroundColor: '#e6f7ff', // Light blue background
      // borderRadius: '4px',
      // padding: '2px 4px',
      fontSize: '10px',
      marginBottom:'auto',
      // color: '#333',
    }),
    ValueContainer: (base)=>({
      ...base,
      padding:'0px',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: '0',
    }),
   
  }

  
  const navigate=useNavigate();
  const addnewFacility=()=>{
    navigate('/service-provider-dashboard/addUnit')
  }

  return (
    <>
    <div className="p-3 mt-3">
    <div className="d-flex justify-content-between">
        <div >
          <p className="main-d-headings a-font">DashBoard</p>
        </div>
        <div className="">
          <button className="d-flex align-items-center justify-content-center gap-2 px-3" onClick={addnewFacility} style={{
            backgroundColor:'#FFCC00',
            height:'37px',
            borderRadius:'6px',
            border:'none',
          }}>
            <img src="/Images/storageFacility.png" alt="storagefacility" style={{width:'18px',height:'18px'}}></img>
          <p className="blueTextColr a-font" style={{fontSize:'14px',fontWeight:500, letterSpacing:'0.3px', margin: 0,}}> Add New Facility</p>
          </button>
        </div>
      </div>
      {loading || totalLoading || availableLoading || occupiedLoading ? (
     <Spinner />
   ) : storageUnits?.results?.length === 0 ? (
     <div>
       <div className="mt-5 text-center">
         <LuWarehouse size={70} />
         <h1 className="mt-2">No Facility Storage Found</h1>
         <p className="mt-2">
           It looks like there are no facility stores available yet. Click
           here to create a new one.
         </p>
         <Link
           to="/service-provider-dashboard/addUnit"
           className="btn btn-dark text-dark"
         >
           <LuWarehouse
             className="pr-1"
             style={{
               marginRight: "10px",
             }}
           />
           ADD NEW FACILITY
         </Link>
       </div>
     </div>
   ) : (
     <>
       <div className="row gx-4">
         <AnalyticsSpaceCards
           totalUnits={totalUnits}
           availableUnits={availableStorageUnits}
           occupiedUnits={occupiedStorageUnits}
           StorageUnitData={storageUnits?.results}
           orders={orders}
         />
       </div>
       <div className="row gx-4 mt-4">
         <AnalyticsMapAndChart
           StorageUnitData={storageUnits?.results}
           loading={loading}
         />
       </div>
       <div className="row gx-4 mt-4">
         <AnalyticsRecentUnitsAndChart
           StorageUnitData={storageUnits?.results}
         />
       </div>
       {/* <div className="row gx-4 mt-4">
         <AnalyticsReviewsTableAndUnitStatus
           StorageUnitData={storageUnits?.results}
           count={storageUnits?.count}
         />
       </div> */}
       {ordersLoading ? (
         <Spinner />
       ) : (
         <div className="service-card p-4 mt-4">
           <div className="card-body">
           <div
               className="d-flex align-items-center justify-content-between"
              //  style={{
              //    gap: "30px",
              //  }}
             >
              <div><p className="service-card-heads a-font mb-0">Order Details</p></div>
              <div className="d-flex align-items-center gap-3">

              <div className="d-flex align-items-center">
                     <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                      <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                  </div>
                  <div 
                    style={{
                      width: '0.5px',         
                      backgroundColor: '#000000', 
                      height: '42px',     
                      margin: '0 10px'    
                    }}
                  ></div>

              <div className="">
                <Select
                  isMulti
                  name="status"
                  options={options}
                  value={selectedOptions}
                  onChange={handleMultiSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  // styles={{
                  //   control: (base) => ({
                  //     ...base,
                  //     width: '122px',
                  //     height: '28px',
                  //     minHeight: '28px',
                  //     border: '0.6px solid #d5d5d5',
                  //     borderRadius:'4px',
                  //     display: 'flex',
                  //     alignItems:'center',
                  //     backgroundColor: "transparent",
                  //     fontSize: "14px",
                  //     outline: "none",
                  //     '&:hover': {
                  //         borderColor: '#D5D5D5',
                  //       },
                  //   }),
                  //   valueContainer: (base) => ({
                  //     ...base,
                  //     width: '122px',
                  //     height: '28px',
                  //     minHeight: '28px',
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //     padding: '0 10px',
                  //     boxSizing:'border-box',
                  //     // padding: '0px',
                  //     // margin: '0px',
                  //   }),
                  //   multiValue: (base) => ({
                  //     ...base,
                  //     height: '16px', 
                  //   }),
                  //   input: (base) => ({
                  //     ...base,
                  //     margin: '0px', 
                  //     padding: '0px',
                  //   }),
                  // }}
                />
              </div>

              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="starting_date"
                  value={selectedData.starting_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="ending_date"
                  value={selectedData.ending_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                  placeholder="Ending Date"
                />
              </div>

              <div className="d-flex align-items-center gap-2">
                  <div className="input-order">
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Search..."
                        value={selectedData.search}
                        onChange={handleTextChange}
                      />
                    
                  </div>
                  <button
                      type="button"
                      onClick={handleFilter}
                      className=""
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '4.5px',
                        width:'30px',
                        height:'28px',
                        color: '#000034',
                      }}
                    >
                      <FaSearch size={14} />
                    </button>
                </div>


              </div>
             </div>
             <HomeOrdersTableServiceProvider orders={orders} />
           </div>
           <div className="d-flex justify-content-center align-items-center">
             <Pagination
               activePage={selectedData.page}
               itemsCountPerPage={10}
               totalItemsCount={orders?.count}
               pageRangeDisplayed={5}
               onChange={handlePaginationChange}
               itemClass="page-item"
               linkClass="page-link"
             />
           </div>
         </div>
       )}
     </>
   )}
    </div>



    </>
  );
};

export default AnalyticsPage;
