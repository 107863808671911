import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../../shared-components/container/Container";
import HomeOrdersTableServiceProvider from "../../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { getAllOrdersAdmin } from "../../../actions/ordersActions";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import { CiReceipt, CiShoppingCart } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";

const OrdersPage = () => {
  const allOrdersAdmin = useSelector((state) => state.allOrdersAdmin);
  const dispatch = useDispatch();
  const { orders, loading } = allOrdersAdmin;
  const [selectedData, setSelectedData] = useState({
    pending: false,
    completed: false,
    starting_date: "",
    ending_date: "",
    search: "",
    page: 1,
  });

  const options = [
    { value: "completed", label: "Completed" },
    { value: "pending", label: "Pending" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedData({
      ...selectedData,
      pending: selectedOptions.some((option) => option.value === "pending"),
      completed: selectedOptions.some((option) => option.value === "completed"),
    });
  }, [selectedOptions]);

  const handleMultiSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleFilter = () => {
    dispatch(getAllOrdersAdmin({ ...selectedData, page: 1 }));
  };

  const handleDateChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (name === "starting_date" || name === "ending_date") {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        value = `${year}-${month}-${day}`;
      }

      setSelectedData({
        ...selectedData,
        [name]: value,
      });
    } else {
      setSelectedData({
        ...selectedData,
        [name]: "",
      });
    }
  };

  const handleTextChange = (e) => {
    setSelectedData({
      ...selectedData,
      search: e.target.value,
    });
  };

  const handlePagination = (pageNumber) => {
    setSelectedData({
      ...selectedData,
      page: pageNumber,
    });
  };

  useEffect(() => {
    dispatch(getAllOrdersAdmin(selectedData));
  }, [selectedData.page]);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '0.6px solid #D5D5D5',
      borderRadius: '4px',
      height: '28px', 
      minHeight: '28px', 
      '&:hover': {
        borderColor: '#D5D5D5',
      },
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: '14px', 
      display: 'flex',
      alignItems: 'center', 
      marginBottom: 'auto', 
    }),
    multiValue: (base) => ({
      ...base,
      // backgroundColor: '#e6f7ff', // Light blue background
      // borderRadius: '4px',
      // padding: '2px 4px',
      fontSize: '10px',
      marginBottom:'auto',
      // color: '#333',
    }),
    ValueContainer: (base)=>({
      ...base,
      padding:'0px',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: '0',
    }),
   
  }

  const [filteredOrders,setFilteredOrders]=useState(orders);
  // apal filters handling
  const handleFilter2 = () => {
    // Destructure the selected data
    const { starting_date, ending_date, search } = selectedData;
  
    const isFilterEmpty = !starting_date && !ending_date && !search && !selectedOptions.length;

    if (isFilterEmpty) {
      // No filter applied, set filteredOrders to all orders
      setFilteredOrders(orders);
      // console.log("No filters applied, showing all orders:", orders);
      return;
    }
    // Convert dates to comparable format
    const startDate = starting_date ? new Date(starting_date) : null;
    const endDate = ending_date ? new Date(ending_date) : null;
  
    // Apply filters
    const filtered = orders.results.filter(order => {
      const orderStartDate = new Date(order.starting_date);
  
      // Check date range
      const isWithinDateRange =
        (!startDate || orderStartDate >= startDate) &&
        (!endDate || orderStartDate <= endDate);
  
      // Check status
      const isStatusMatch = selectedOptions.length
        ? selectedOptions.some(option => order[option.value]) // Match against selected statuses
        : true;
  
      // Check search term (case-insensitive)
      const isSearchMatch = search
      ? Object.keys(order).some(key => {
          const value = order[key];
          if (typeof value === "object" && value !== null) {
            // If value is an object, check nested properties
            return Object.values(value)
              .some(nestedValue =>
                nestedValue?.toString().toLowerCase().includes(search.toLowerCase())
              );
          } else {
            // Check primitive values
            return value?.toString().toLowerCase().includes(search.toLowerCase());
          }
        })
      : true;
      // Combine all conditions
      return isWithinDateRange && isStatusMatch && isSearchMatch;
    });


    const filteredOrdersObj = {
      ...orders,       
      results: filtered
    };

    setFilteredOrders(filteredOrdersObj);
  
    // console.log("Filtered :", filteredOrdersObj);
  };
  
  
  
  // console.log("~~~orders ", orders);
  // console.log("~~~xooo ", filteredOrders);


  return (
    <div className="py-2">
      <Container>
        <div className="align-items-center d-none d-lg-flex justify-content-between">
          <div className="d-flex align-items-center">
            <p className="main-d-headings a-font blueTextColr">Orders</p>
          </div>
        </div>

        <div className="service-card p-4 mt-4">
          <div className="card-body">
          <div
               className="d-flex align-items-center justify-content-between"
              //  style={{
              //    gap: "30px",
              //  }}
             >
              <div><p className="service-card-heads a-font mb-0">Order Details</p></div>
              <div className="d-flex align-items-center gap-3">

              <div className="d-flex align-items-center">
                     <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                      <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                  </div>
                  <div 
                    style={{
                      width: '0.5px',         
                      backgroundColor: '#000000', 
                      height: '42px',     
                      margin: '0 10px'    
                    }}
                  ></div>

              <div className="">
                <Select
                  isMulti
                  name="status"
                  options={options}
                  value={selectedOptions}
                  onChange={handleMultiSelectChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  // styles={{
                  //   control: (base) => ({
                  //     ...base,
                  //     width: '122px',
                  //     height: '28px',
                  //     minHeight: '28px',
                  //     border: '0.6px solid #d5d5d5',
                  //     borderRadius:'4px',
                  //     display: 'flex',
                  //     alignItems:'center',
                  //     backgroundColor: "transparent",
                  //     fontSize: "14px",
                  //     outline: "none",
                  //     '&:hover': {
                  //         borderColor: '#D5D5D5',
                  //       },
                  //   }),
                  //   valueContainer: (base) => ({
                  //     ...base,
                  //     width: '122px',
                  //     height: '28px',
                  //     minHeight: '28px',
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //     padding: '0 10px',
                  //     boxSizing:'border-box',
                  //     // padding: '0px',
                  //     // margin: '0px',
                  //   }),
                  //   multiValue: (base) => ({
                  //     ...base,
                  //     height: '16px', 
                  //   }),
                  //   input: (base) => ({
                  //     ...base,
                  //     margin: '0px', 
                  //     padding: '0px',
                  //   }),
                  // }}
                />
              </div>

              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="starting_date"
                  value={selectedData.starting_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="ending_date"
                  value={selectedData.ending_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                  placeholder="Ending Date"
                />
              </div>

              <div className="d-flex align-items-center gap-2">
                  <div className="input-order">
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Search..."
                        value={selectedData.search}
                        onChange={handleTextChange}
                      />
                    
                  </div>
                  <button
                      type="button"
                      onClick={handleFilter2}
                      className=""
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '4.5px',
                        width:'30px',
                        height:'28px',
                        color: '#000034',
                      }}
                    >
                      <FaSearch size={14} />
                    </button>
                </div>


              </div>
             </div>
            {loading ? (
              <Spinner />
            ) : (
              <HomeOrdersTableServiceProvider orders={filteredOrders} />
            )}
          </div>
          <div
            className="d-flex justify-content-center align-items-center mb-5"
            style={{
              gap: "50px",
            }}
          >
            <Pagination
              activePage={selectedData.page}
              itemsCountPerPage={10} // Number of items per page
              totalItemsCount={orders?.count || 1} // Total number of items
              pageRangeDisplayed={5} // Number of pages to display in the pagination
              onChange={handlePagination} // Callback function to handle page changes
              itemClass="page-item" // Bootstrap class for each pagination item
              linkClass="page-link" // Bootstrap class for pagination links
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OrdersPage;
