import { useState , useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

import Spinner from "../../../../shared-components/Spinner/Spinner";
import { FaSearch } from "react-icons/fa";

function PromotionsTable({
  promotions,
  editPromotionHandler,
  deletePromotionHandler,
}) {
  const [unitsQuantity, setUnitsQuantity] = useState(0);
  const [rowInfo, setRowInfo] = useState({
    index: -1,
    promo_id: 0,
  });
  function deletePromotionModal(index, row) {
    setUnitsQuantity(row.storage_unit_ids.length);
    setRowInfo({ index: index, promo_id: row.id });
  }

  // for promotions filters
  // State for managing filters
  const [filters, setFilters] = useState({
    search: "",
    type: "", 
    starting_date:"",
    ending_date:"",
  });

  // State for filtered promotions
  const [filteredPromotions, setFilteredPromotions] = useState(promotions);

  useEffect(() => {
    let result = promotions;
  
    // Filter by Search
    if (filters.search) {
      result = result.filter((unit) =>
        unit.title.toLowerCase().includes(filters.search.toLowerCase())
      );
    }
  
    // Filter by Type
    if (filters.type) {
      result = result.filter((unit) => unit.type === filters.type);
    }
  
    // Filter by Date
    if (filters.starting_date || filters.ending_date) {
      const startDate = filters.starting_date
        ? new Date(filters.starting_date)
        : null;
      const endDate = filters.ending_date ? new Date(filters.ending_date) : null;
  
      result = result.filter((item) => {
        if (item.created_at) {
          const createdAtDate = new Date(item.created_at);
  
          if (startDate && createdAtDate <= startDate) {
            return false;
          }
          if (endDate && createdAtDate >= endDate) {
            return false;
          }
          return true;
        }
        return false;
      });
    }
  
    setFilteredPromotions(result);
  }, [filters, promotions]);
  

  const handleTextChange = (e) => {
    setFilters({ ...filters, search: e.target.value });
  };

  const handleTypeChange = (e) => {
    setFilters({ ...filters, type: e.target.value });
  };

  const handleDateChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (name === "starting_date" || name === "ending_date") {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        value = `${year}-${month}-${day}`;
      }

      setFilters({
        ...filters,
        [name]: value,
      });
    } else {
      setFilters({
        ...filters,
        [name]: "",
      });
    }
  };

  // console.log("~~ promo " ,filteredPromotions );

  return (
    <>
      <div className="service-card p-4 mt-4">
           <div className="card-body">
           <div
               className="d-flex align-items-center justify-content-between"
             >
              <div><p className="service-card-heads a-font mb-0">Promotions</p></div>
              <div className="d-flex align-items-center gap-3">

              <div className="d-flex align-items-center">
                     <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                      <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                  </div>
                  <div 
                    style={{
                      width: '0.5px',         
                      backgroundColor: '#000000', 
                      height: '42px',     
                      margin: '0 10px'    
                    }}
                  ></div>

              <div className="input-order">
                <select
                  className="custom-input"
                  value={filters.type}
                  onChange={handleTypeChange}
                  style={{ width: "150px" }}
                >
                  <option value="">Select Type</option>
                  <option value="dollar_off">In Dollars</option>
                  <option value="initial_discount_price">Fixed Amount</option>
                  <option value="percentage_off">In Percentage</option>
                </select>
              </div>

              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="starting_date"
                  value={filters.starting_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="ending_date"
                  value={filters.ending_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                  placeholder="Ending Date"
                />
              </div>

              <div className="d-flex align-items-center gap-2">
                  <div className="input-order">
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Search..."
                        value={filters.search}
                        onChange={handleTextChange}
                      />
                    
                  </div>
                  <button
                      type="button"
                      // onClick={handleFilter}
                      className=""
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '4.5px',
                        width:'30px',
                        height:'28px',
                        color: '#000034',
                      }}
                    >
                      <FaSearch size={14} />
                    </button>
                </div>


              </div>
             </div>
      {filteredPromotions ? (
        <div className="table-responsive">
          <table className="table mt-4">
          <thead className="text-center">
              <tr className="">
                <th className="order-table-content bg-gray p-2 py-3" style={{borderTopLeftRadius:'12px',borderBottomLeftRadius:'12px'}} scope="col">NAME</th>
                <th className="order-table-content bg-gray p-2 py-3" scope="col">CREATED AT</th> 
                <th className="order-table-content bg-gray p-2 py-3" scope="col">PROMOTION TYPE</th>
                <th className="order-table-content bg-gray p-2 py-3" scope="col">DURATION</th>
                <th className="order-table-content bg-gray p-2 py-3" style={{borderTopRightRadius:'12px',borderBottomRightRadius:'12px'}} scope="col">ACTION</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredPromotions.map((row, index) => (
                <tr key={index}>
                    <td className="order-table-bcontent p-4">{row.title}</td>
                    <td className="order-table-bcontent p-4">{row.created_at}</td>
                    <td className="order-table-bcontent p-4">
                    <span className="py-1"
                        style={{
                            fontWeight: '700',
                            fontSize: '12px',
                            backgroundColor: 'rgba(0, 182, 155, 0.2)',
                            borderRadius: '4.5px',
                            color: '#00B69B',
                            display: 'inline-flex',        
                            alignItems: 'center',    
                            justifyContent: 'center', 
                            maxWidth:'117px',
                            width:'117px',
                          }}>
                         {row.type === 'dollar_off' && 'In Dollars'}
                         {row.type === 'initial_discount_price' && 'Fixed Amount'}
                         {row.type === 'percentage_off' && 'In Percentage'}
                      </span>
                    </td>
                    <td className="order-table-bcontent p-4">{row.offer_months}/{row.total_months} Months</td>
                   <td className="">
                          {/* <p>Here</p> */}
                          <div className="d-flex gap-2 align-items-center justify-content-center" style={{
                            backgroundColor:'#FAFBFD',
                            border:'0.6px solid #D5D5D5',
                            borderRadius:'8px',
                            maxWidth:'96px'
                          }}>
                            <div className="d-flex" onClick={() => editPromotionHandler(index)} style={{cursor:'pointer'}}>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.6">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69732 10.4239L7.22266 10.7779L7.57599 8.30261L13.94 1.93861C14.5258 1.35282 15.4755 1.35282 16.0613 1.93861C16.6471 2.5244 16.6471 3.47415 16.0613 4.05994L9.69732 10.4239Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.2324 2.646L15.3538 4.76733" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.5 10.5V15.5C13.5 16.0523 13.0523 16.5 12.5 16.5H2.5C1.94772 16.5 1.5 16.0523 1.5 15.5V5.5C1.5 4.94772 1.94772 4.5 2.5 4.5H7.5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              </svg>

                            </div>
                            <div className="d-flex">
                            <svg width="1" height="33" viewBox="0 0 1 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.700544" d="M0.5 32.7641V1" stroke="#979797" stroke-width="0.4" stroke-linecap="square"/>
                              </svg>
                            </div>

                            <button onClick={() => deletePromotionModal(index, row)}
                            data-bs-toggle="modal"
                            data-bs-target="#deletePromotionModal"
                              style={{border:'none',backgroundColor:'transparent' ,cursor:"pointer"}}
                              >
                            <div className="d-flex">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1996 15.3999H4.79961C4.13687 15.3999 3.59961 14.8626 3.59961 14.1999V3.3999H14.3996V14.1999C14.3996 14.8626 13.8624 15.3999 13.1996 15.3999Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.19883 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M10.7984 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M1.19922 3.4H16.7992" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8 1H7.2C6.53726 1 6 1.53726 6 2.2V3.4H12V2.2C12 1.53726 11.4627 1 10.8 1Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                            </button>

                          </div>
                      </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Spinner />
      )}

      <div
        className="modal fade"
        id="deletePromotionModal"
        tabIndex={-1}
        aria-labelledby="deletePromotionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Delete Promotion
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              Are you sure you want to delete this promotion?
              <p>No. of Attached Storage Units: {unitsQuantity}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() =>
                  deletePromotionHandler(rowInfo.index, rowInfo.promo_id)
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}
export default PromotionsTable;
