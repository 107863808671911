import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { RxCross2 } from "react-icons/rx";
import { BsCheck2 } from "react-icons/bs";
import { deleteRoom } from "../../../../actions/roomsActions";
import { getTotalStorageUnits } from "../../../../actions/storageUnitActions";
import { Tooltip } from "react-tippy";

import "react-tippy/dist/tippy.css";
import { FaCircle } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";

const StorageUnitsRoomsTable = ({ storageUnits }) => {
  const dispatch = useDispatch();
  let unitsData = storageUnits?.results;
  // console.log("~~~" ,unitsData);
    return (
      <div className="table-responsive">
        <table className="table mt-4">
          <thead className="text-center">
                <tr className="">
                  <th className="order-table-content bg-gray p-2" style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} scope="col">IMAGE</th> 
                  <th className="order-table-content bg-gray p-2" scope="col">UNIT</th>
                  <th className="order-table-content bg-gray p-2" scope="col">FACILITY</th>
                  <th className="order-table-content bg-gray p-2" scope="col">STATUS</th>
                  <th className="order-table-content bg-gray p-2" scope="col">LOCATION</th>
                  <th className="order-table-content bg-gray p-2" scope="col">PRICE</th>
                  <th className="order-table-content bg-gray p-2" style={{borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}} scope="col">ACTIONS</th>
                </tr>
          </thead>
          <tbody className="text-center">
              {unitsData?.map((unit) => (
                 <tr key={unit.id}>
                      <td className="d-flex justify-content-center align-items-center" style={{
                          }}>
                            {/* {unit.storage_facility.facility_images[0]?.image ?
                            (
                            <img src={unit?.facility_images[0]?.image}  alt="Storage Facility Image" 
                              style={{width:'120px' ,height:'120px',
                                objectFit: 'cover',
                                borderRadius:'8px',
                              }} />
                              
                            )
                            :
                            ( */}
                              <div className="d-flex align-items-center justify-content-center"
                              style={{width:'120px' ,height:'120px',
                                objectFit: 'cover',
                                borderRadius:'8px',
                                backgroundColor:'#D8D8D8',
                              }}
                            >
                              {/* <p>{unit.name?.charAt(0).toUpperCase()}</p> */}
                              <p className="n-font" style={{
                                fontSize:'50px',
                                fontWeight:800,
                                color:'#FFCC00',
                                marginBottom:'0px'
                              }}
                              >{unit?.name?.slice(0, 2).toUpperCase()}</p>
                            </div>
                            {/* )} */}
                      </td>
                      <td className="order-table-bcontent p-4">{unit.name}</td>
                      <td className="order-table-bcontent p-4">{unit.storage_facility.name}</td>
                      <td className="order-table-bcontent p-4">
                      {unit.is_available ? (
                         <span 
                         style={{
                             fontWeight: '500',
                             fontSize: '12px',
                             backgroundColor: '#FFDED166',
                             borderRadius: '118px',
                             color: '#FF9871',
                             padding: '2px 10px',
                             display: 'inline-flex',         
                             alignItems: 'center',    
                             justifyContent: 'center', 
                             border:'1px solid #FF9871',
                             gap:'3px',
                           }}>
                             <FaCircle size={6}></FaCircle>
                         Available
                       </span>
                      ) : ( 
                       <span 
                       style={{
                           fontWeight: '500',
                           fontSize: '12px',
                           backgroundColor: '#D9F7E866',
                           borderRadius: '118px',
                           color: '#5CDD9C',
                           padding: '2px 10px',
                           display: 'inline-flex',         
                           alignItems: 'center',    
                           justifyContent: 'center', 
                           border:'1px solid #5CDD9C',
                           gap:'3px',
                         }}>
                           <FaCircle size={6}></FaCircle>
                           Booked
                     </span>
                      )}
                      </td>
                      <td className="order-table-bcontent p-4">
                          {unit.storage_facility.location.address && (() => {
                            const parts = unit.storage_facility.location.address.split(',');
                            const beforeFirstComma = parts[0].trim();
                            const afterLastComma = parts[parts.length - 1].trim();
                            return `${beforeFirstComma} - ${afterLastComma}`;
                          })()}
                        </td>
                      <td className="order-table-bcontent p-4">{unit.per_unit_price}</td>
                      
                        <td className="">
                          {/* <p>Here</p> */}
                          <div className="d-flex align-items-center justify-content-center gap-3 px-1" style={{
                            backgroundColor:'#FAFBFD',
                            border:'0.6px solid #D5D5D5',
                            borderRadius:'8px',
                          }}>
                                <Link
                              to={`/service-provider-dashboard/editRoom/${unit.id}`}>
                             <div className="d-flex">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69732 10.4239L7.22266 10.7779L7.57599 8.30261L13.94 1.93861C14.5258 1.35282 15.4755 1.35282 16.0613 1.93861C16.6471 2.5244 16.6471 3.47415 16.0613 4.05994L9.69732 10.4239Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.2324 2.646L15.3538 4.76733" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.5 10.5V15.5C13.5 16.0523 13.0523 16.5 12.5 16.5H2.5C1.94772 16.5 1.5 16.0523 1.5 15.5V5.5C1.5 4.94772 1.94772 4.5 2.5 4.5H7.5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                </svg>

                            </div>
                            </Link>
                            <div className="d-flex">
                            <svg width="1" height="33" viewBox="0 0 1 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.700544" d="M0.5 32.7641V1" stroke="#979797" stroke-width="0.4" stroke-linecap="square"/>
                              </svg>
                            </div>

                            <button onClick={() => dispatch(deleteRoom(unit.id))}
                              style={{border:'none',backgroundColor:'transparent'}}
                              >
                            <div className="d-flex">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1996 15.3999H4.79961C4.13687 15.3999 3.59961 14.8626 3.59961 14.1999V3.3999H14.3996V14.1999C14.3996 14.8626 13.8624 15.3999 13.1996 15.3999Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.19883 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M10.7984 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M1.19922 3.4H16.7992" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8 1H7.2C6.53726 1 6 1.53726 6 2.2V3.4H12V2.2C12 1.53726 11.4627 1 10.8 1Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                            </button>

                          </div>
                      </td>
                       {/* <Link
                          to={`/service-provider-dashboard/editRoom/${unit.id}`}
                          className="btn btn-sm btn-dark text-dark"
                        >
                          Edit
                        </Link>

                        <button
                          className="btn btn-sm text-dark"
                          onClick={() => dispatch(deleteRoom(unit.id))}
                        >
                          Delete
                        </button> */}

                 </tr>
              ))}
          </tbody>
        </table>
    </div>
    );
  };


















  //   return {
  //     id: unit.id,
  //     name: unit.name,
  //     storage_facility: unit.storage_facility.name,
  //     location: unit.storage_facility.location.address,
  //     created_at: unit.created_at,
  //     total_space: unit.size_field,
  //     occupied_space: unit.occupied_space_field,
  //     free_space: unit.free_space_field,
  //     per_unit_price: unit.per_unit_price,
  //     is_available: unit.is_available,
  //   };
  // });

  // const columnsStorageUnitRooms = [
  //   {
  //     Header: "Name",
  //     accessor: "name",
  //   },
  //   {
  //     Header: "Facility",
  //     accessor: "storage_facility",
  //   },
  //   {
  //     Header: "Location",
  //     accessor: "location",
  //     Cell: ({ value }) => (
  //       <Tooltip title={value} position="top" trigger="mouseenter">
  //         <span className="truncate-text">{truncateText(value, 5)}</span>
  //       </Tooltip>
  //     ),
  //   },

  //   {
  //     Header: "Created At",
  //     accessor: "created_at",
  //     Cell: ({ value }) => {
  //       return format(new Date(value), "dd/mm/yyyy");
  //     },
  //   },
  //   {
  //     Header: "Total Units",
  //     accessor: "total_space",
  //   },
  //   {
  //     Header: "Price",
  //     accessor: "per_unit_price",
  //     Cell: ({ value }) => `$${value.toFixed(0)}`,
  //   },
  //   {
  //     Header: "Is Available",
  //     accessor: "is_available",
  //     Cell: ({ value }) => {
  //       return value ? (
  //         <BsCheck2 className="text-success" />
  //       ) : (
  //         <RxCross2 className="text-danger" />
  //       );
  //     },
  //   },
  //   {
  //     Header: "",
  //     accessor: "id",
  //     Cell: ({ value }) => {
  //       return (
  //         <div className="dropdown">
  //           <button
  //             className="bg-white border border-0 rounded"
  //             type="button"
  //             unitsData-bs-toggle="dropdown"
  //             aria-expanded="false"
  //           >
  //             <BsThreeDots size={30} />
  //           </button>

  //           <ul className="dropdown-menu">
  //             <Link
  //               className="dropdown-item"
  //               to={`/service-provider-dashboard/editRoom/${value}`}
  //             >
  //               Edit
  //             </Link>
  //             <li
  //               className="dropdown-item pointer"
  //               onClick={() => dispatch(deleteRoom(value))}
  //             >
  //               Delete
  //             </li>
  //           </ul>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  // function truncateText(text, maxWords) {
  //   const words = text.split(" ");
  //   if (words.length <= maxWords) {
  //     return text;
  //   }
  //   return words.slice(0, maxWords).join(" ") + "...";
  // }

  // const columns = useMemo(() => columnsStorageUnitRooms, []);
  // const unitsData = useMemo(() => unitsData, []);

  // const tableInstance = useTable(
  //   {
  //     columns,
  //     unitsData,
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination,
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   page,
  //   headerGroups,
  //   state,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   setGlobalFilter,
  //   prepareRow,
  //   pageOptions,
  // } = tableInstance;

  // const { globalFilter, pageIndex } = state;

//   return (
//     <div>
//       {/* Table */}
//       <div className="table-responsive">
//         <table className="table table-hover table-striped" {...getTableProps()}>
//           <thead className="text-center">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="bg-orange text-dark"
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     colSpan={1}
//                     {...column.getHeaderProps(column.getSortByToggleProps())}
//                   >
//                     {column.render("Header")}
//                     <span>
//                       {column.isSorted
//                         ? column.isSortedDesc
//                           ? "🔽"
//                           : "🔼"
//                         : ""}
//                     </span>
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody className="text-center" {...getTableBodyProps()}>
//             {page.map((row) => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

export default StorageUnitsRoomsTable;
