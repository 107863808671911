import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { LuWarehouse } from "react-icons/lu";

import { getAllStorageUnits } from "../../actions/storageUnitActions";
import Spinner from "../../shared-components/Spinner/Spinner";
import UnitStorageStatusTable from "../ServiceProviderDashboard/ServiceProviderDashboardComponents/UnitStorageStatusTable";
import StorageFacilityFilter from "../../shared-components/Filter/StorageFacilityFilter";

const StorageFacilities = () => {
  const { loading, storageUnits } = useSelector(
    (state) => state.getAllStorageUnits,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Dispatch the action inside the useEffect when currentPage changes
    dispatch(getAllStorageUnits(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    dispatch(getAllStorageUnits()); // Initial load
  }, [dispatch]);

  const navigate=useNavigate();
  const addnewFacility=()=>{
    navigate('/service-provider-dashboard/addUnit')
  }


  //for strage facility filters

  const [filteredStorageUnits, setFilteredStorageUnits] = useState(storageUnits?.results);

    // Handle filter application
    const handleFilterApply = (filteredData) => {
        setFilteredStorageUnits(filteredData);
    };
    
  // const [selectedFilters, setSelectedFilters] = useState({
  //   is_available: false,
  //   is_occupied: false,
  //   ordering: "-created_at",
  //   page: 1,
  //   name: "",
  //   storage_facility__name: "",
  // });

  
  // const handleFilterChange = (selectedOptions) => {
  //   const updatedFilters = { ...selectedFilters };
  //   setSelectedFilters(updatedFilters);
  // };

  // const updateFiltersAndSearch = (updatedFilters) => {
  //   setSelectedFilters(updatedFilters);
  //   dispatch(getAllStorageUnits(updatedFilters));
  // };

  
  return (
    <>
      <div className="d-flex justify-content-between">
        <div >
          <p className="main-d-headings a-font">Storage Facilities</p>
        </div>
        <div className="">
          <button className="d-flex align-items-center justify-content-center gap-2 px-3" onClick={addnewFacility} style={{
            backgroundColor:'#FFCC00',
            height:'37px',
            borderRadius:'6px',
            border:'none',
          }}>
            <img src="/Images/storageFacility.png" alt="storagefacility" style={{width:'18px',height:'18px'}}></img>
          <p className="blueTextColr a-font" style={{fontSize:'14px',fontWeight:500, letterSpacing:'0.3px', margin: 0,}}> Add New Facility</p>
          </button>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : storageUnits?.results?.length === 0 ? (
        <div>
          <div className="mt-5 text-center">
            <LuWarehouse size={70} />
            <h1 className="mt-2">No Facility Storage Found</h1>
            <p className="mt-2">
              It looks like there are no facility stores available yet. Click
              here to create a new one.
            </p>
            <Link
              to="/service-provider-dashboard/addUnit"
              className="btn btn-dark text-dark"
            >
              <LuWarehouse
                className="pr-1"
                style={{
                  marginRight: "10px",
                }}
              />
              ADD NEW FACILITY
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="service-card p-4 mt-4">
            <div className="col-12">
              {loading ? (
                <Spinner />
              ) : (
                <>
                      <StorageFacilityFilter 
                        data={storageUnits?.results} 
                        onFilterApply={handleFilterApply}  // Pass the callback to the filter component
                    />
                    
                    <UnitStorageStatusTable 
                        data={filteredStorageUnits}  // Pass the filtered data to the table
                    />
                </>
              )}
            </div>
            <div className="text-center m-auto">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={storageUnits?.count}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StorageFacilities;
