import React, { useState } from "react";
import { Link, useNavigate ,createSearchParams} from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useSelector } from "react-redux";
import { BsCartCheck } from "react-icons/bs";
import Notification from "../Notifications/Notifications";
import "./navbar.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import {IoArrowDown} from "react-icons/io5";
import { IoWarning } from "react-icons/io5";

import { logoutUser } from "../../actions/userActions";
import { USER_INFO_CLEAR } from "../../constants/userConstants";
import { Spinner } from "react-bootstrap";
import activateStripe from "../../hooks/activateStripe";
import SizeGuideDropdownContent from "./SizeGuideDropdownContent";
import { FaUser } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";

const Navbar = () => {
  const { user } = useSelector((state) => state.userLogin);
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const { message, error } = useSelector((state) => state.userLogout);
  const { info } = useSelector((state) => state.userInfo);

  const { cartItems, loading } = useSelector((state) => state.cartGetItems);
  const navigate = useNavigate();
  const [stripeLoading, setStripeLoading] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState(false); // State for navbar toggle

  const dispatch = useDispatch();
  let storageUnits;
  if (!loading && cartItems) {
    storageUnits = cartItems[0].storage_unit;
  }

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
  }, [message, error]);

  async function handleActiveStripe() {
    setStripeLoading(true);
    const status = await activateStripe(user);
    setStripeLoading(status);
  }

  // Handlers
  const logoutHandler = () => {
    dispatch(logoutUser());
    dispatch({ type: USER_INFO_CLEAR });
    navigate("/");
  };
  const homeLink =
    info && info.is_service_provider
      ? "/service-provider-dashboard/analytics"
      : "/";

  /////////To find storage ; BY deafult in button prop location is new york
  const [formDatabutton, setFormDatabutton] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const ButtonState = (location) => {
    setFormDatabutton((prev) => {
      const updatedData = { ...prev, location };
  
      // console.log("Updated form data:", updatedData);
      localStorage.setItem("us_Storage_facility", JSON.stringify(updatedData));
  
      navigate({
        pathname: "/storages/results",
        search: `?${createSearchParams(updatedData)}`,
      });
  
      return updatedData;
    });
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light nav" style={{
        backgroundColor:' #ffcc00'
      }}
    >
      <div
        className="container-fluid p-3"
        style={{ width: "94%" , backgroundColor:'#000034' , height:'80px', marginTop: "20px",  
          borderRadius: "25px"
          }}
      >
        <Link to={homeLink} className="navbar-brand">
        <span
            className="mineTextOrange ms-3"
            style={{ marginRight: "10px", position: "relative", display: "inline-block" }}
          >
            <img src="/images/FindxStorage3.png" height={40} alt="Logo" />
            
            {info && info.is_service_provider && (
              <small
                className="text-light"
                style={{
                  position: "absolute", 
                  top: "100%",          
                  left: "50%",          
                  transform: "translateX(-50%)",
                  fontSize: "13px",
                }}
              >
                Service Provider
              </small>
            )}
          </span>

        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsNavOpen(!isNavOpen)}
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavOpen}
          aria-label="Toggle navigation"
          style={{ color: "white" }}
        >
          <i className="fas fa-bars"></i>
        </button>
        <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
            style={{
              backgroundColor: isNavOpen ? "#000034" : "transparent", // Change color if nav is open
            }}
          >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
          <li className="nav-item m-0 m-lg-1 m-xl-2">
              <Link to={`http://blog.findxstorage.com/`} className="nav-link text-light"
              style={{ fontWeight: '600', fontSize: '16px' }}

              >
                Blogs
              </Link>
            </li>
            <li className="nav-item m-0 m-lg-1 m-xl-2">
              <Link to={`/SizingGuidPage`} className="nav-link text-light"
              style={{ fontWeight: '600', fontSize: '16px' }}
              >
                Size Guide
              </Link>
            </li>

            <li className="nav-item m-0 m-lg-1 m-xl-2">
              <Link to={`/storage-type`} className="nav-link text-light"
              style={{ fontWeight: '600', fontSize: '16px' }}
              >
                Storage Help
              </Link>
            </li>

            <li className="nav-item m-0 m-lg-1 m-xl-2">
              <Link
                to="/about-us"
                className="nav-link text-nowrap text-light"
                activeclassname="active-link"
                style={{ fontWeight: '600', fontSize: '16px' }}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item m-0 m-lg-1 m-xl-2">
              <button
                className="m-3"
                style={{
                  fontWeight: '700',
                  fontSize: '18px',
                  backgroundColor: '#ffcc00',
                  border: 'none',
                  borderRadius: '10px',
                  padding: '10px',
                  color: '#000034',
                  paddingLeft:'20px',
                  paddingRight:'20px',
                }}
                onClick={() => ButtonState("New York")}
              >
                Find Storage
                <FaSearch size={20} style={{ marginLeft: '8px' }} />
              </button>
            </li>

            {user && info ? (
              <>
                {user && !user.service_provider && (
                  <li className="nav-item mx-2">
                    <Link to="/cart" className="nav-link text-light">
                      <button className="border-0 p-2 px-3 bg-outline-orange position-relative text-nowrap">
                        <BsCartCheck size={20} />
                        Cart
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {(cartItems && storageUnits.length) || 0}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </button>
                    </Link>
                  </li>
                )}
                <li className="nav-item m-0 m-lg-1 m-xl-2 my-3">
                  <Notification />
                </li>
                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle text-light"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="">
                      {user &&
                        user.service_provider &&
                        !user.is_stripe_account_active && (
                          <IoWarning
                            size={22}
                            color="orange"
                            className="warning_sign mb-1"
                          />
                        )}
                       Hi, {serviceProvider?.name || info?.first_name || "User"}
                    </span>
                  </span>
                  <ul className="dropdown-menu ">
                    {user &&
                      user.service_provider &&
                      !user.is_stripe_account_active && (
                        <li>
                          <button
                            className="dropdown-item alert alert-warning"
                            onClick={handleActiveStripe}
                          >
                            <IoWarning size={25} color="orange" /> Please
                            activate your Stripe account{" "}
                            {stripeLoading && (
                              <Spinner
                                animation="border"
                                variant="warning"
                                size="sm"
                              />
                            )}
                          </button>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/myprofile"
                          >
                            Profile
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/myprofile">
                          Profile
                        </Link>
                      </li>
                    )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/analytics"
                          >
                            Dashboard
                          </Link>
                        </li>
                      )}
                    {user &&
                      user.service_provider &&
                      user.is_provider_verified && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/service-provider-dashboard/orders"
                          >
                            Orders
                          </Link>
                        </li>
                      )}
                    {!user?.service_provider && (
                      <li>
                        <Link className="dropdown-item" to="/orders">
                          Orders
                        </Link>
                      </li>
                    )}
                    <li className="m-2">
                      <button
                        className="btn text-dark "
                        style={{ width: "100%" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item m-0 m-lg-1 m-xl-2">
                  <Link to="/sign-in">
                    <FaUser size={27} color="white"></FaUser>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
