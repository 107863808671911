import React, { useEffect, useState } from "react";
import GeoApiAuto from "../../GeoApiAuto/GeoApiAuto";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";

const ButtonGroupAndForm = ({ changeView, onChangeView }) => {
  const [formData, setFormData] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const dispatch = useDispatch();

  // Submit Form Handler

  const submitFormHandler = (event) => {
    event.preventDefault();

    dispatch(getAllUsStorageFacilities(formData));
  };

  const getLocationData = (data, id) => {
    if (!data) {
      return setFormData((prev) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }

    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };

    setFormData((prev) => {
      return {
        ...prev,
        [id]: locationData.address,
      };
    });
  };

  // Handle Side Effects
  useEffect(() => {
    let previousState = JSON.parse(localStorage.getItem("us_Storage_facility"));
    setFormData((prev) => ({
      ...prev,
      ...previousState,
    }));
  }, []);

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center flex-md-row flex-column gap-2">
        <div
        className="d-flex flex-row p-1 gap-2"
        style={{border:'0.3px solid #97979799' ,borderRadius:'8px'}}
          
        >
          <button
            onClick={() =>
              onChangeView({ list: !changeView.list, map: !changeView.map })
            }
            className="p-2 px-3 px-lg-4 px-xl-5"
            style={{
              backgroundColor: changeView.list ? '#ffcc00' : 'transparent',
              border:'none',
              borderRadius:'8px',
            }}            
          >
            <AiOutlineUnorderedList /> List
          </button>

          <button
            onClick={() =>
              onChangeView({ list: !changeView.list, map: !changeView.map })
            }
            className="p-2 px-3 px-lg-4 px-xl-5"
            style={{
              backgroundColor: changeView.list ? 'transparent' : '#ffcc00',
              border:'none',
              borderRadius:'8px',
            }}  
            
          >
            <FaMapMarkerAlt /> Maps
          </button>
        </div>
        <div className="col-9 service-card p-2 px-4">
          <form className="d-flex align-items-center justify-content-between mt-4 mb-4 mb-md-0 mt-md-0">
          <div className="form-group" style={{width:'80%'}}>
              <div style={{ width: '100%' , border:'0.3px solid #97979799',borderRadius:'8px' , paddingLeft:'5px'}}>
                <GeoApiAuto id="location" getLocationData={getLocationData} />
              </div>
        </div>
            {/* <button
              onClick={submitFormHandler}
              type="submit"
              className="border-0 p-2 mt-3 rounded ms-2 bg-orange text-dark btn"
            >
              Find Units
            </button> */}
            <button
            className="m-3"
              style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  backgroundColor: '#ffcc00',
                  border: 'none',
                  borderRadius: '4.5px',
                  color: '#000034',
                  paddingTop: '6px',   
                  paddingBottom: '6px',
                  paddingLeft: '28px', 
                  paddingRight: '28px', 
              }}
              onClick={submitFormHandler}
          >
              Find Units
              <FaSearch size={13} style={{ marginLeft: '8px' }} />
          </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ButtonGroupAndForm;
