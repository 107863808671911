import React from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineAnalytics } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { CiReceipt } from "react-icons/ci";
import { CiSettings } from "react-icons/ci";
import { LiaWarehouseSolid } from "react-icons/lia";
import { LuWarehouse } from "react-icons/lu";
import { RiDashboard3Line } from "react-icons/ri";
import { CiInboxIn } from "react-icons/ci";
import { BsListCheck } from "react-icons/bs";
import { IoIosPower } from "react-icons/io";

import { logoutUser } from "../../../actions/userActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_INFO_CLEAR } from "../../../constants/userConstants";

import '../style.css'

const ServiceProviderDashboardSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logoutUser());
    dispatch({ type: USER_INFO_CLEAR });
    navigate("/");
  };
  return (
   <div className="h-100 ps-5 mt-4">
     <div className="h-100 bg-white fullHeight" style={{borderRadius:'25px'}}>
      <div className="px-4 pt-5">
        <NavLink
          to="/service-provider-dashboard/analytics"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
              : isActive
                ? "d-flex p-3 align-items-center gap-3 activesidebar"
                : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
          
        >
          <RiDashboard3Line size={24}/>
          <p className="m-0">Dashboard</p>
        </NavLink>
        {/* <NavLink
          to="/service-provider-dashboard/inbox"
          className={({ isActive, isPending }) =>
            isPending
              ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
              : isActive
                ? "d-flex p-3 align-items-center gap-3 activesidebar"
                : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <CiInboxIn size={24}/>
          <h6 className="m-0">Inbox</h6>
        </NavLink> */}
        <NavLink
          to="/service-provider-dashboard/orders"
          className={({ isActive, isPending }) =>
            isPending
          ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
          : isActive
            ? "d-flex p-3 align-items-center gap-3 activesidebar"
            : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <BsListCheck size={24}/>
          <h6 className="m-0">Order Lists</h6>
        </NavLink>
      </div>

      <div style={{ border: "0.6px solid #E0E0E0", margin: "1rem 0" }}></div>

      <div className="px-4">
      <p className="text-uppercase ps-3" style={{color:'#202224',fontSize:'12px',fontWeight:700}}>Pages</p>
          <NavLink
          to="/service-provider-dashboard/storagefacilities"
          className={({ isActive, isPending }) =>
            isPending
          ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
          : (isActive || window.location.pathname.startsWith('/service-provider-dashboard/addUnit'))
            ? "d-flex p-3 align-items-center gap-3 activesidebar"
            : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <LuWarehouse size={24}/>
          <h6 className="m-0">Storage Facility</h6>
        </NavLink>
        <NavLink
          to="/service-provider-dashboard/storageUnits"
          className={({ isActive, isPending }) =>
            isPending
          ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
          // : (isActive || window.location.pathname.startsWith('/service-provider-dashboard/addRoom/:id'))
          : isActive || /^\/service-provider-dashboard\/addRoom\/\d+$/.test(window.location.pathname)
            ? "d-flex p-3 align-items-center gap-3 activesidebar"
            : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <LiaWarehouseSolid size={24} />
          <h6 className="m-0">Storage Units</h6>
        </NavLink>



        <NavLink
          to="/service-provider-dashboard/promotions"
          className={({ isActive, isPending }) =>
            isPending
          ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
          : isActive
            ? "d-flex p-3 align-items-center gap-3 activesidebar"
            : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <CiDiscount1 size={24} />
          <h6 className="m-0">Promotions</h6>
        </NavLink>

      </div>

      <div style={{ border: "0.6px solid #E0E0E0", margin: "1rem 0" }}></div>
      <div className="px-4">

        <NavLink
          to="/service-provider-dashboard/myprofile"
          className={({ isActive, isPending }) =>
            isPending
          ? "d-flex p-3 align-items-center gap-3 notactivesidebar"
          : isActive
            ? "d-flex p-3 align-items-center gap-3 activesidebar"
            : "d-flex p-3 align-items-center gap-3 notactivesidebar"
          }
        >
          <CiSettings size={24} />
          <h6 className="m-0">Settings</h6>
        </NavLink>
        <NavLink
          onClick={logoutHandler}
          className="d-flex p-3 align-items-center gap-3 notactivesidebar"
        >
          <IoIosPower size={24}/>
          <h6 className="m-0">Log Out</h6>
        </NavLink>
      </div>
      
    </div>
   </div>
  );
};

export default ServiceProviderDashboardSidebar;
