import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteStorageUnit } from "../../../actions/storageUnitActions";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { useState } from "react";

const UnitStorageStatusTable = ({ data }) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.deleteStorageUnit);

  useEffect(() => {
    if (error) {
      toast.error("Something Went Wrong");
    }
  }, [error]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  // console.log("~~ data" , data);
  // facility it is 
  return (
    <div className="table-responsive">
        <table className="table mt-4">
          <thead className="text-center">
                <tr className="">
                  <th className="order-table-content bg-gray p-2" style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} scope="col">IMAGE</th> 
                  <th className="order-table-content bg-gray p-2" scope="col">NAME</th>
                  <th className="order-table-content bg-gray p-2" scope="col">CREATED AT</th>
                  <th className="order-table-content bg-gray p-2" scope="col">UNITS AVAILABLE</th>
                  <th className="order-table-content bg-gray p-2" scope="col">LOCATION</th>
                  <th className="order-table-content bg-gray p-2" scope="col">ACTIONS</th>
                  <th className="order-table-content bg-gray p-2" style={{borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}} scope="col"></th>
                </tr>
          </thead>
          <tbody className="text-center">
              {data?.map((unit) => (
                 <tr key={unit.id}>
                      <td className="d-flex justify-content-center align-items-center" style={{
                          }}>
                        {unit.facility_images[0]?.image ?
                        (<img src={unit.facility_images[0]?.image}  alt="Storage Facility Image" 
                          style={{width:'120px' ,height:'120px',
                            objectFit: 'cover',
                            borderRadius:'8px',
                          }} />)
                        :
                        (
                          <div className="d-flex align-items-center justify-content-center"
                          style={{width:'120px' ,height:'120px',
                            objectFit: 'cover',
                            borderRadius:'8px',
                            backgroundColor:'#D8D8D8',
                          }}
                        >
                          {/* <p>{unit.name?.charAt(0).toUpperCase()}</p> */}
                          <p className="n-font" style={{
                            fontSize:'50px',
                            fontWeight:800,
                            color:'#FFCC00',
                            marginBottom:'0px'
                          }}
                          >{unit.name?.slice(0, 2).toUpperCase()}</p>
                        </div>
                        )
                        }
                      </td>
                      <td className="order-table-bcontent p-4">{unit.name}</td>
                      <td className="order-table-bcontent p-4">{unit.created_at.substring(0, 10)}</td>
                      <td className="order-table-bcontent p-4">{unit.available_storage_units ? unit.available_storage_units : '0'}</td>
                      {/* <td>{unit.location.address}</td> */}
                      <td className="order-table-bcontent p-4">
                          {unit.location.address && (() => {
                            const parts = unit.location.address.split(',');
                            const beforeFirstComma = parts[0].trim();
                            const afterLastComma = parts[parts.length - 1].trim();
                            return `${beforeFirstComma} - ${afterLastComma}`;
                          })()}
                        </td>
                        <td className="">
                          {/* <p>Here</p> */}
                          <div className="d-flex align-items-center justify-content-center gap-3 px-1" style={{
                            backgroundColor:'#FAFBFD',
                            border:'0.6px solid #D5D5D5',
                            borderRadius:'8px',
                          }}>
                                <Link
                               to={`/service-provider-dashboard/storagefacilities/${unit.id}`}>
                             <div className="d-flex">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69732 10.4239L7.22266 10.7779L7.57599 8.30261L13.94 1.93861C14.5258 1.35282 15.4755 1.35282 16.0613 1.93861C16.6471 2.5244 16.6471 3.47415 16.0613 4.05994L9.69732 10.4239Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.2324 2.646L15.3538 4.76733" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.5 10.5V15.5C13.5 16.0523 13.0523 16.5 12.5 16.5H2.5C1.94772 16.5 1.5 16.0523 1.5 15.5V5.5C1.5 4.94772 1.94772 4.5 2.5 4.5H7.5" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                </svg>

                            </div>
                            </Link>
                            <div className="d-flex">
                            <svg width="1" height="33" viewBox="0 0 1 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.700544" d="M0.5 32.7641V1" stroke="#979797" stroke-width="0.4" stroke-linecap="square"/>
                              </svg>
                            </div>

                            <button onClick={() => dispatch(deleteStorageUnit(unit.id))}
                              style={{border:'none',backgroundColor:'transparent'}}
                              >
                            <div className="d-flex">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1996 15.3999H4.79961C4.13687 15.3999 3.59961 14.8626 3.59961 14.1999V3.3999H14.3996V14.1999C14.3996 14.8626 13.8624 15.3999 13.1996 15.3999Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.19883 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M10.7984 11.8V7" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M1.19922 3.4H16.7992" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8 1H7.2C6.53726 1 6 1.53726 6 2.2V3.4H12V2.2C12 1.53726 11.4627 1 10.8 1Z" stroke="#EF3826" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                            </button>

                          </div>
                      </td>
                 </tr>
              ))}
          </tbody>
        </table>
    </div>
  );
};

export default UnitStorageStatusTable;
