import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const StorageFacilityFilter = ({ data, onFilterApply }) => {
    const [filters, setFilters] = useState({
        location: '',
        starting_date:'',
        ending_date:'',
        search: ''
    });

    const handleDateChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (value) {
          if (name === "starting_date" || name === "ending_date") {
            const date = new Date(value);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            value = `${year}-${month}-${day}`;
          }
    
          setFilters({
            ...filters,
            [name]: value,
          });
        } else {
          setFilters({
            ...filters,
            [name]: "",
          });
        }
      };

    // Extract unique dates from data.created_at
    const uniqueDates = [...new Set(data?.map((item) => item.created_at.substring(0, 10)))];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // const handleDateChange = (e) => {
    //     setSelectedDate(e.target.value);
    // };

    const applyFilters = () => {
        let result = data;
        if (filters.location) {
            result = result.filter((unit) => {
                return unit.location && typeof unit.location.address === 'string' && unit.location.address.toLowerCase().includes(filters.location.toLowerCase());
            });
        }

        
        if (filters.starting_date && filters.ending_date) {
            const startDate = new Date(filters.starting_date);
            const endDate = new Date(filters.ending_date);
        
             // Adjust endDate to include the entire day
            // endDate.setHours(23, 59, 59, 999);

            result = result.filter(item => {
                if (item.created_at) {
                    const createdAtDate = new Date(item.created_at);
        
                    return createdAtDate >= startDate && createdAtDate <= endDate;
                }
                return false;
            });
        }
        
        

        if (filters.search) {
            result = result.filter((unit) =>
                unit.name.toLowerCase().includes(filters.search.toLowerCase())
            );
        }


        // console.log("~~ facility filters  " , result);
        onFilterApply(result); 
    };

    return (
        <div className="d-flex align-items-center justify-content-between">
            <div>
                <p className="service-card-heads a-font mb-0">Storage Facility Details</p>
            </div>
            <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p className="mb-0 ms-2" style={{ fontSize: '14px', fontWeight: 600, color: '#00034' }}>Filter By</p>
                </div>
                <div
                    style={{
                        width: '0.5px',
                        backgroundColor: '#000000',
                        height: '42px',
                        margin: '0 10px'
                    }}
                ></div>

                <div className="input-order">
                    <input
                        type="text"
                        name="location"
                        value={filters.location}
                        onChange={handleFilterChange}
                        placeholder="Location"
                        className="custom-input"
                    />
                </div>

                {/* <div className="input-order">
                    <select value={selectedDate} onChange={handleDateChange} className="custom-input">
                        <option value="">Date</option>
                        {uniqueDates.map((date) => (
                            <option key={date} value={date}>
                                {date}
                            </option>
                        ))}
                    </select>
                </div> */}

            <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="starting_date"
                  value={filters.starting_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                />
              </div>
              <div className="input-order">
                <input
                  type="date"
                  className="custom-input"
                  name="ending_date"
                  value={filters.ending_date}
                  onChange={handleDateChange}
                  style={{ height: "36px" }}
                  placeholder="Ending Date"
                />
              </div>

                <div className="d-flex align-items-center gap-2">
                    <div className="input-order">
                        <input
                            type="text"
                            value={filters.search}
                            onChange={handleFilterChange}
                            name="search"
                            placeholder="Search..."
                            className="custom-input"
                        />
                    </div>
                    <button
                        type="button"
                        onClick={applyFilters}
                        className=""
                        style={{
                            backgroundColor: '#ffcc00',
                            border: 'none',
                            borderRadius: '4.5px',
                            width: '30px',
                            height: '28px',
                            color: '#000034',
                        }}
                    >
                        <FaSearch size={14} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StorageFacilityFilter;
