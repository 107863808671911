import React from "react";
import ServiceProviderDashboardSidebar from "./ServiceProviderDashboardSidebar/ServiceProviderDashboardSidebar";
import ServiceProviderDashboardContent from "./ServiceProviderDashboardContent/ServiceProviderDashboardContent";

import "./style.css";

const DashboardMainPage = () => {
  return (
    <div className="d-flex"style={{backgroundColor:'#F5F6FA'}} >
      <div className="d-none d-lg-block seller-dashboard-sidebar">
        <ServiceProviderDashboardSidebar />
      </div>
      <div
        className="seller-dashboard-content"
        style={{backgroundColor:'#F5F6FA'}}
      >
        <div className="container-fluid p-1 p-md-3">
          <ServiceProviderDashboardContent />
        </div>
      </div>
    </div>
  );
};

export default DashboardMainPage;
